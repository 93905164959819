import React from 'react';

import { useFetchColumns } from '@api/columns';
import { SearchModel } from '@api/search/SearchModel';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import QuickSearchInput from '@components/QuickSearch/QuickSearchInput';
import stripSpaces from '@utils/stripSpaces';

import SelectedSearchItemPill from './SelectedSearchItemPill';
import { SelectedMeasure } from './types';

interface ColumnSelectProps {
  addSelectedItem: (value: SelectedMeasure) => void;
  isPatch?: boolean;
  removeSelectedItem: (value: SelectedMeasure) => void;
  selectedItems: SelectedMeasure[];
  selectedMeasure: SelectedMeasure;
  setSelectedItems: (value: SelectedMeasure[]) => void;
}

const QUERY = stripSpaces(`{
  guid,
  name,
  search_name,
  field_type,
  data_type,
  external_type,
  is_join_key,
  data_types
}`);

const ColumnSelect: React.FC<ColumnSelectProps> = ({
  addSelectedItem,
  isPatch,
  removeSelectedItem,
  selectedItems,
  selectedMeasure,
  setSelectedItems,
}) => {
  const { isLoading } = useFetchColumns({
    enabled: !isPatch,
    onSuccess: (data) => {
      setSelectedItems(data.results.filter(({ guid }) => selectedMeasure.guid !== guid));
    },
    params: { order: '-popularity', query: QUERY, tables: selectedMeasure.parentGuid },
    staleTime: 0,
  });

  // Filter already selected results before displaying to the user.
  const filterResults = (data: SearchModel[]) => {
    return data.filter(
      ({ guid }) =>
        selectedMeasure.guid !== guid && !selectedItems.some((selected) => selected.guid === guid),
    );
  };

  if (isLoading) return <CircularLoader centered compSize={5} cover />;

  return (
    <>
      <QuickSearchInput
        filters={[
          {
            filters: [{ field: 'parent_guid', values: [selectedMeasure.parentGuid!] }],
            indexes: ['columns'],
          },
        ]}
        onBeforeResult={filterResults}
        onResultSelect={addSelectedItem}
      />
      <Box compHeight="16rem" compWidth="100%" mt={1} noDefault overflow="auto">
        {selectedItems.map((column) => {
          return (
            <div key={column.guid} style={{ marginBottom: '0.5rem', width: '100%' }}>
              <SelectedSearchItemPill
                onRemove={() => {
                  removeSelectedItem(column);
                }}
                selectedItem={column}
              />
            </div>
          );
        })}
      </Box>
    </>
  );
};

export default ColumnSelect;
