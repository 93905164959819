import React, { useEffect, useState } from 'react';

import Tooltip from '@components/Tooltip.v1';
import theme from '@styles/theme';
import useLocalStorage from '@utils/useLocalStorage';

import defaultSections, { MainSidebarSections } from './AppMainSidebar.config';
import { StyledAppMainSidebar, StyledAppMainSidebarCollapseButton } from './AppMainSidebar.styles';
import { SidebarItemId, SidebarItemOnClickParams } from './AppMainSidebar.types';
import PrimarySidebar from './PrimarySidebar';
import SecondarySidebar from './SecondarySidebar';
import { useAppMainSidebar } from './useAppMainSidebar';

interface AppMainSidebarProps {
  sections?: MainSidebarSections;
}

const SECONDARY_SIDEBAR_ITEM_ENABLER_EXCEPTIONS = [
  SidebarItemId.notifications,
  SidebarItemId.settings,
  SidebarItemId.helpDocs,
];

const AppMainSidebar = ({ sections = defaultSections }: AppMainSidebarProps) => {
  const { activeMenuItemId, dataSourcesSection, setActiveMenuItemId, zIndex } = useAppMainSidebar();
  const [isPrimarySidebarCollapsed, setIsPrimarySidebarCollapsed] = useState(
    Boolean(activeMenuItemId),
  );
  const [isSecondarySidebarCollapsed, setIsSecondarySidebarCollapsed] = useLocalStorage(
    'isSecondarySidebarCollapsed',
    false,
  );

  const handleHomeClick = () => {
    setActiveMenuItemId(undefined);
    setIsPrimarySidebarCollapsed(false);
  };

  useEffect(() => {
    setIsPrimarySidebarCollapsed(Boolean(activeMenuItemId));
  }, [activeMenuItemId]);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth <= theme.breakpoints.md) {
      setIsPrimarySidebarCollapsed(true);
    }
  }, []);
  const sectionsWithDataSources = dataSourcesSection
    ? { ...sections, dataSourcesSection }
    : sections;
  const finalSections = Object.values(sectionsWithDataSources)
    .sort((prevConfig, nextConfig) => prevConfig.order - nextConfig.order)
    .map((config) => ({
      ...config,
      items: config.items.map((item) => ({
        ...item,
        onClick: (params: SidebarItemOnClickParams) => {
          if (!SECONDARY_SIDEBAR_ITEM_ENABLER_EXCEPTIONS.includes(item.id as SidebarItemId)) {
            item.onClick?.(params);
            setIsPrimarySidebarCollapsed(true);
            setActiveMenuItemId(params.id);
          }
        },
      })),
    }));

  const tooltipContent = `${isSecondarySidebarCollapsed ? 'Expand' : 'Collapse'} sidebar`;

  return (
    <StyledAppMainSidebar zIndex={zIndex}>
      {isPrimarySidebarCollapsed && (
        <Tooltip action content={tooltipContent}>
          <StyledAppMainSidebarCollapseButton
            aria-label="Expand or collapse secondary sidebar"
            iconColor="primary.500"
            iconName={isSecondarySidebarCollapsed ? 'right' : 'left'}
            iconSize="16px"
            onClick={() => setIsSecondarySidebarCollapsed((current) => !current)}
          />
        </Tooltip>
      )}
      <PrimarySidebar
        activeMenuItemId={activeMenuItemId}
        isCollapsed={isPrimarySidebarCollapsed}
        onHomeClick={handleHomeClick}
        sections={finalSections}
      />
      <SecondarySidebar
        activeMenuItemId={activeMenuItemId}
        isCollapsed={isSecondarySidebarCollapsed}
        isVisible={isPrimarySidebarCollapsed}
        sections={finalSections}
      />
    </StyledAppMainSidebar>
  );
};

export default AppMainSidebar;
