import React, { memo, useCallback, useState } from 'react';
import type { Cell } from 'react-table';

import type { DatasetModel } from '@api/datasets/DatasetModel.v1';
import DateTime from '@components/DateTime';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import UserCell from '@components/Table/Cells/UserCell';
import type { TableProps } from '@components/Table/Table';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import Tooltip from '@components/Tooltip';
import { PaginatedResponse } from '@models/PaginatedResponse';
import type { FilterServiceInterface } from '@utils/filters';
import { FilterOptions } from '@utils/filters';

const ALL_COLUMNS = [
  'search',
  'name',
  'tags',
  'dsuser',
  'createdAt',
  'updatedAt',
  'sourceTablesCount',
  'popularity',
  'description',
] as const;
type ColumnKey = (typeof ALL_COLUMNS)[number];

const DEFAULT_VISIBLE_COLUMNS = [
  'search',
  'name',
  'description',
  'tags',
  'dsuser',
  'createdAt',
  'updatedAt',
  'sourceTablesCount',
  'popularity',
] as const;

export const DATASET_TABLE_SEARCH_CONFIG: PartialRecord<ColumnKey, keyof FilterOptions> = {
  description: 'search_description',
  dsuser: 'search_dsuser_created_by',
  name: 'search_name',
  tags: 'search_tags',
};

export const DATASET_TABLE_SORT_CONFIG: PartialRecord<ColumnKey, string> = {
  createdAt: 'created_at',
  description: 'description',
  dsuser: 'dsuser_created_by',
  name: 'name',
  popularity: 'popularity',
  sourceTablesCount: 'source_tables_count',
  updatedAt: 'updated_at',
};

export interface DatasetTableProps extends Pick<TableProps, 'selectedRowIds'> {
  data?: PaginatedResponse<DatasetModel>;
  filterService: FilterServiceInterface;
  isDataSourceEditable?: boolean;
  isLoading?: boolean;
  toggleAll?: (checked: boolean) => void;
  toggleItem?: (item: DatasetModel, checked: boolean) => void;
  visibleColumns?: Array<ColumnKey>;
}

const DatasetTable: React.FC<DatasetTableProps> = ({
  data,
  filterService,
  isDataSourceEditable = false,
  isLoading,
  selectedRowIds,
  toggleAll,
  toggleItem,
  visibleColumns = DEFAULT_VISIBLE_COLUMNS,
}) => {
  const [isShowFilter, setIsShowFilter] = useState(false);
  const { changePage, filter, initialTableSortState, search, sort } = filterService;
  const toggleFilter = useCallback(() => setIsShowFilter((prev) => !prev), [setIsShowFilter]);

  const columns: ColumnConfig<DatasetModel>[] = React.useMemo(() => {
    const all: Record<ColumnKey, ColumnConfig<DatasetModel>> = {
      createdAt: {
        Cell: ({ row: { original } }: Cell<DatasetModel>) => (
          <DateTime datetime={original.createdAt} />
        ),
        Header: 'Created',
        accessor: (d) => d?.createdAt?.toDate() ?? 'N/A',
        disableFilters: true,
        id: 'createdAt',
        sortDescFirst: true,
        width: '115%',
      },
      description: {
        Cell: ({ column, row: { original }, state }: Cell<DatasetModel>) => (
          <DescriptionCell
            {...original}
            column={column}
            dataSourceType={original.dataTypes?.dataSourceType}
            isDataSourceEditable={isDataSourceEditable}
            state={state}
          />
        ),
        Header: 'Description',
        accessor: 'description',
        id: 'description',
        width: '124.5%',
      },
      dsuser: {
        Cell: ({ column, row: { original }, state }: Cell<DatasetModel>) => (
          <UserCell column={column} state={state} user={original?.formattedDsuserCreatedBy} />
        ),
        Header: 'Created By',
        accessor: (d) => d.formattedDsuserCreatedBy?.fullName,
        id: 'dsuser',
        width: '120%',
      },
      name: {
        Cell: ({ column, row: { original }, state }: Cell<DatasetModel>) => (
          <LinkedCell
            column={column}
            item={original}
            itemName={original.name}
            showDataTypeTooltip
            showIcon
            state={state}
          />
        ),
        Header: `Name (${data?.count ?? 0})`,
        accessor: 'name',
        disableHiding: true,
        id: 'name',
        width: '135%',
      },
      popularity: {
        Cell: ({ row: { original } }: Cell<DatasetModel>) => (
          <PopularityCell isDashboard popularity={original?.popularity} />
        ),
        Header: PopularityCellHeader,
        accessor: (d) => d.popularity?.formattedPopularity,
        disableFilters: true,
        disableResizing: true,
        id: 'popularity',
        sortDescFirst: true,
        width: 120,
      },
      search: {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      sourceTablesCount: {
        Header: (
          <Tooltip content="Count of upstream data tables connected to this dashboard / report">
            <span>Source Tables</span>
          </Tooltip>
        ),
        accessor: (d) => d.sourceTablesCount,
        disableFilters: true,
        dropdownCheckboxLabel: 'Source Tables',
        id: 'sourceTablesCount',
        sortDescFirst: true,
        width: 150,
      },
      tags: {
        Cell: ({ row: { original } }: Cell<DatasetModel>) => (
          <EditableTaggedItemCell isDataSourceEditable={isDataSourceEditable} obj={original} />
        ),
        Header: 'Tags',
        accessor: (d) => d.taggedItems,
        disableSortBy: true,
        id: 'tags',
        width: '130%',
      },
      updatedAt: {
        Cell: ({ row: { original } }: Cell<DatasetModel>) => (
          <DateTime datetime={original.updatedAt} />
        ),
        Header: 'Last Modified',
        accessor: (d) => d.updatedAt,
        disableFilters: true,
        id: 'updatedAt',
        sortDescFirst: true,
        width: '115%',
      },
    };

    return visibleColumns.map((col) => all[col]);
  }, [data?.count, isDataSourceEditable, visibleColumns]);

  const getRowId = useCallback((row: Partial<DatasetModel>) => row.guid!, []);
  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        compact
        data={data?.results || []}
        getRowId={getRowId}
        initialState={{
          hiddenColumns: ['tags', 'createdAt'],
          ...(selectedRowIds && { selectedRowIds }),
          sortBy: initialTableSortState,
        }}
        loading={isLoading}
        manualFilters
        manualPagination
        manualSortBy
        rangeSelectConfig={{
          onCellCheckChange: toggleItem,
          onHeaderCheckChange: toggleAll,
        }}
        selectable
        setFilters={search}
        setSortBy={sort}
        showFilter={isShowFilter}
        sortable
        stickyHeader
        toggleFilter={toggleFilter}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default memo(DatasetTable);
