import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LOADING_TEXT } from '@constants';
import { useHistory } from '@routing/router';
import { useRecoilState } from 'recoil';

import { FetchErdParams, useFetchErd } from '@api/lineage';
import TableLineageModel from '@api/lineage/TableLineageModel';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import ExploreTree from '@components/ExploreTree';
import { exploreOptions } from '@components/ExploreTree/atoms';
import { ExploreOptionItem, ExploreOptionKey } from '@components/ExploreTree/ExploreOptions';
import { OpenLineageCallback } from '@components/ExploreTree/types';
import Tooltip from '@components/Tooltip';
import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';
import Icon from '@components/UI/Icon';
import { useModal } from '@context/Modal';
import useMergeLineageData from '@hooks/useMergeLineageData';
import useUrlSearchParams from '@hooks/useUrlSearchParams';
import useLocalStorage from '@utils/useLocalStorage';

import {
  ERD_EXPLORE_ID,
  ERD_FILTERED_TABLES_IDS_SEARCH_PARAM_NAME,
  ERD_SELECTED_COLUMNS_IDS_SEARCH_PARAM_NAME,
  ERD_SELECTED_TABLES_IDS_SEARCH_PARAM_NAME,
} from './consts';
import {
  ErdOption,
  ErdOptionsPane,
  LoadingText,
  SideBarContainer,
  StyledExploreModal,
  StyledExploreModalHeader,
  ToggleSidebarButton,
} from './ErdExplore.styles';
import ErdExploreSidebar from './ErdExploreSidebar';

export interface ErdExploreProps {
  columnsIds: string[];
  getStackStartingTableId: (tables: TableLineageModel[]) => string;
  onModalClose?: () => void;
  tablesIds: string[];
}

const ErdExplore: React.FC<ErdExploreProps> = ({
  columnsIds,
  getStackStartingTableId,
  onModalClose,
  tablesIds,
}) => {
  const history = useHistory();
  const searchParamsObj = useUrlSearchParams();
  const [options, setOptions] = useRecoilState(exploreOptions);
  const { MODAL_IDS, closeModal, openModal } = useModal();
  const [sideBarWidth, setSideBarWidth] = useLocalStorage('erdSideBarWidth', 300);
  const [visibleTablesIds, setVisibleTablesIds] = useState<string[] | null>(() => {
    const idsArr = searchParamsObj?.get(ERD_FILTERED_TABLES_IDS_SEARCH_PARAM_NAME);

    return idsArr && idsArr?.length > 0 ? idsArr?.split(',') : null;
  });
  const finalTablesIds =
    tablesIds?.length > 0
      ? tablesIds
      : searchParamsObj?.get(ERD_SELECTED_TABLES_IDS_SEARCH_PARAM_NAME)?.split(',') ?? [];
  const finalColumnsIds =
    columnsIds?.length > 0
      ? columnsIds
      : searchParamsObj?.get(ERD_SELECTED_COLUMNS_IDS_SEARCH_PARAM_NAME)?.split(',') ?? [];
  const tablesIdsSearchStr = finalTablesIds.join(',');
  const columnsIdsSearchStr = finalColumnsIds.join(',');
  const [{ isSidebarVisible, sidebarKey, sidebarType }, setSidebar] = useState<{
    isSidebarVisible: boolean;
    sidebarKey: string | null;
    sidebarType: 'table' | 'column';
  }>({
    isSidebarVisible: true,
    sidebarKey: null,
    sidebarType: 'table',
  });

  const { data, isLoading: isDataLoading } = useFetchErd({
    enabled: Boolean(tablesIdsSearchStr) || Boolean(columnsIdsSearchStr),
    params: {
      columns: columnsIdsSearchStr,
      include_borderline_edges: true,
      mode: 'all',
      tables: tablesIdsSearchStr,
    },
  });

  const [extraRequestParams, setExtraRequestParams] = useState<FetchErdParams>();

  const { data: extraErdData, isLoading: isExtraErdDataLoading } = useFetchErd({
    enabled: Boolean(extraRequestParams),
    params: extraRequestParams,
  });

  const isLoading = isDataLoading || isExtraErdDataLoading;

  const erdDataMerged = useMergeLineageData(
    {
      columns: [...(data?.columns ?? []), ...(extraErdData?.columns ?? [])],
      tables: [...(data?.tables ?? []), ...(extraErdData?.tables ?? [])],
    },
    { deepMerge: true },
  );

  const startingTableId = useMemo(() => {
    return getStackStartingTableId(erdDataMerged?.tables ?? []);
  }, [erdDataMerged?.tables]);

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      /** Disables autoClose by default for ERD. */
      autoClose: {
        ...prev.autoClose,
        value: false,
      },
    }));

    /** Reset to default on ErdExplore close. */
    return () => {
      setOptions((prev) => ({
        ...prev,
        autoClose: {
          ...prev.autoClose,
          value: true,
        },
      }));
    };
  }, [startingTableId, setOptions]);

  useEffect(() => {
    setSidebar((prev) => ({
      ...prev,
      isSidebarVisible: Boolean(startingTableId),
      sidebarKey: startingTableId,
    }));
  }, [startingTableId]);

  const openSidebar = useCallback(
    (type: 'table' | 'column', key: string) => {
      setSidebar({ isSidebarVisible: true, sidebarKey: key, sidebarType: type });
    },
    [setSidebar],
  );

  const toggleSidebar = () => {
    setSidebar((prev) => ({ ...prev, isSidebarVisible: !prev.isSidebarVisible }));
  };

  useEffect(() => {
    if (columnsIdsSearchStr) {
      searchParamsObj.set(ERD_SELECTED_COLUMNS_IDS_SEARCH_PARAM_NAME, columnsIdsSearchStr);
    }

    if (tablesIdsSearchStr) {
      searchParamsObj.set(ERD_SELECTED_TABLES_IDS_SEARCH_PARAM_NAME, tablesIdsSearchStr);
    }

    history.push({ search: searchParamsObj.toString() });
  }, [tablesIdsSearchStr, columnsIdsSearchStr]);

  const toggleLineageOption = useCallback(
    (key: ExploreOptionKey) => {
      // Find `option` in lineageOptions and reverse `option.value` boolean
      setOptions((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          value: !prev[key]?.value,
        },
      }));
    },
    [setOptions],
  );

  const loadErd = useCallback<OpenLineageCallback>(
    ({ columns, componentIdentifier, tables }) => {
      setExtraRequestParams({
        column_level_traversal: Boolean(columns),
        columns,
        component_identifier_override: componentIdentifier, // fixes BE issue and ensure connected nodes loaded with the same id and rendered in teh same stack.
        include_borderline_edges: true,
        mode: 'all',
        tables,
      });
    },
    [setExtraRequestParams],
  );

  const handleRequestClose = (_?: any, isUnmount?: boolean) => {
    closeModal(MODAL_IDS.exploreErd);
    if (isUnmount) return;
    onModalClose?.();
  };

  useEffect(() => {
    openModal(MODAL_IDS.exploreErd);
  }, [MODAL_IDS.exploreErd, openModal]);

  return (
    <StyledExploreModal
      onClose={handleRequestClose}
      renderContent={({ modalHandleClose }) => (
        <Box
          alignItems="stretch"
          compDisplay="flex"
          compHeight="100%"
          compWidth="100%"
          id={ERD_EXPLORE_ID}
        >
          <SideBarContainer
            defaultSize={{ height: '100%', width: sideBarWidth }}
            enable={{ right: isSidebarVisible }}
            isVisible={isSidebarVisible}
            maxWidth={isSidebarVisible ? 500 : 65}
            minWidth={isSidebarVisible ? 300 : 65}
            onResizeStop={(_e, _direction, ref) =>
              ref.offsetWidth < 300 ? setSideBarWidth(300) : setSideBarWidth(ref.offsetWidth)
            }
          >
            <ToggleSidebarButton
              onClick={toggleSidebar}
              onKeyDown={toggleSidebar}
              role="button"
              tabIndex={-1}
            >
              <Icon name={isSidebarVisible ? 'left' : 'right'} />
            </ToggleSidebarButton>
            {isSidebarVisible && startingTableId && (
              <ErdExploreSidebar
                columns={erdDataMerged?.columns}
                nodeKey={sidebarKey}
                onAllCheckboxesClick={(ids) => {
                  history.push({ search: '' });
                  setVisibleTablesIds(ids);
                }}
                onCheckboxItemClick={(ids) => {
                  searchParamsObj.set(
                    ERD_FILTERED_TABLES_IDS_SEARCH_PARAM_NAME,
                    erdDataMerged?.tables?.length === ids.length || ids.length === 0
                      ? ''
                      : ids?.join(','),
                  );
                  history.push({ search: `?${searchParamsObj}` });
                  setVisibleTablesIds(ids);
                }}
                showTabs={erdDataMerged && erdDataMerged?.tables?.length > 0}
                startingTableId={startingTableId}
                tables={erdDataMerged?.tables ?? []}
                type={sidebarType}
                visibleTablesIds={
                  visibleTablesIds === null
                    ? erdDataMerged?.tables?.map((table) => table.key) ?? []
                    : visibleTablesIds
                }
              />
            )}
          </SideBarContainer>
          <Box compDisplay="flex" compWidth="100%" flexDirection="column">
            <StyledExploreModalHeader
              borderBottom="1px solid transparent"
              onClose={modalHandleClose}
              title={
                <>
                  Data Model (ERD)
                  {isLoading && <CircularLoader borderWidth={2} compSize={2.5} />}
                  <ErdOptionsPane>
                    {Object.entries(options)
                      .filter(([key]) => ['autoClose'].includes(key))
                      .filter(([, value]) => value !== undefined)
                      .map(([key, value]) => [key, value!] as [string, ExploreOptionItem])
                      .map(([key, { label, tooltip, value }]) => (
                        <ErdOption key={key}>
                          <Tooltip content={tooltip ?? label}>
                            <InputLabel>
                              <Checkbox
                                checked={value}
                                disabled={isLoading}
                                name={key}
                                onChange={() => {
                                  toggleLineageOption(key as ExploreOptionKey);
                                }}
                              />
                              {label}
                            </InputLabel>
                          </Tooltip>
                        </ErdOption>
                      ))}
                  </ErdOptionsPane>
                </>
              }
            />
            {isDataLoading && erdDataMerged.tables.length === 0 && (
              <LoadingText>{LOADING_TEXT}</LoadingText>
            )}
            {!isDataLoading && erdDataMerged?.tables?.length === 0 && (
              <LoadingText>No ERD detected.</LoadingText>
            )}
            {erdDataMerged && erdDataMerged?.tables?.length > 0 && (
              <ExploreTree
                columns={erdDataMerged?.columns}
                getStackStartingTableId={getStackStartingTableId}
                loadLineage={loadErd}
                makeStartingTablesActive
                openAllTables
                openSidebar={openSidebar}
                renderContext=""
                startingTablesIds={startingTableId ? [startingTableId] : undefined}
                tables={erdDataMerged?.tables?.filter((table) =>
                  visibleTablesIds === null ? true : visibleTablesIds?.includes(table.key),
                )}
              />
            )}
          </Box>
        </Box>
      )}
    />
  );
};

export default ErdExplore;
