import React, { useEffect, useMemo, useState } from 'react';
import { difference } from 'lodash';

import { usePatchTaggedItem } from '@api/taggedItems';
import { usePatchTags } from '@api/tags';
import { invalidateTagsDependenciesQueries } from '@api/tags/cacheDependencies';
import { TaggedItemsUpdateData, TagModel } from '@api/tags/TagModel';
import Box from '@components/Box';
import CircularLoader from '@components/CircularLoader';
import IconButton, { IconButtonProps } from '@components/IconButton';
import { renderErrorToast, renderInfoToast } from '@components/Toast';
import Tooltip from '@components/Tooltip';
import { VariantColor } from '@components/UI/Form/Checkbox/Checkbox.styles.variants';
import Icon from '@components/UI/Icon';
import type { Option, RenderCustomAnchorArgs, SelectValue } from '@components/UI/Select';
import Select, { SelectProps } from '@components/UI/Select/Select';
import getInitialMap from '@context/TagPropagation/getInitialMap';
import { getFinalState } from '@context/TagPropagation/TagPropagationProvider';
import { useTagsHierarchyContext } from '@context/TagsHierarchy';
import { useUserContext } from '@context/User';
import { SelectedBulkItem, TagsCounts } from '@hooks/useBulkEditSelected';
import theme from '@styles/theme';

import EmptyMessage from './EmptyMessage';
import { getTaggedItems } from './TagsSelect.utils';

export const TAGS_SELECT_WIDTH = 240;

const isSuggestedOption = (option: Option) => option?.checkboxColor !== 'pale-purple';

interface CustomAnchorArgs extends Omit<RenderCustomAnchorArgs, 'selectedItem'> {
  isLoading: boolean;
}

export interface TagsSelectProps extends Pick<SelectProps, 'popperConfigProps'> {
  disableTagsUpdate?: boolean;
  hideCategoryTags?: boolean;
  iconButtonProps?: IconButtonProps;
  isEditable?: boolean;
  /*
   * Objects that the tags will be edited
   * To build the objects array use the useBulkEditSelected hook
   */
  objects: SelectedBulkItem[];
  onSave?: (selectedItems: Option[]) => void;
  renderCustomAnchor?: (args: CustomAnchorArgs) => React.ReactNode;
  renderSelectedItems?: (selectedItems: Option[]) => React.ReactNode;
  /*
   *  How many objectGuids have each tag as a taggedItem
   */
  taggedItemsCounts: TagsCounts;
}

const TagsSelect: React.FC<TagsSelectProps> = ({
  disableTagsUpdate = false,
  hideCategoryTags = false,
  iconButtonProps,
  isEditable = false,
  objects,
  onSave,
  popperConfigProps,
  renderCustomAnchor,
  renderSelectedItems,
  taggedItemsCounts,
}) => {
  const [selectedItems, setSelectedItems] = useState<Option[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const { tagsSelectOptions } = useTagsHierarchyContext();
  const { organization } = useUserContext();
  const useSuggestedTags = organization?.settings?.useSuggestedTags || false;
  const { isLoading: isMutateTagsLoading, mutateAsync: mutateTags } = usePatchTags({
    onError: (err) => {
      renderErrorToast(err.data.detail || 'Error updating the tags');
    },
  });

  const { isLoading: isMutateTaggedItemsLoading, mutateAsync: mutateTaggedItem } =
    usePatchTaggedItem(undefined, {
      onError: (err) => {
        renderErrorToast(err.data.detail || 'Error updating the tags');
      },
    });

  const options = useMemo(() => {
    const tagOptions = [];
    if (tagsSelectOptions?.category && !hideCategoryTags) {
      tagOptions.push(tagsSelectOptions.category);
    }
    if (tagsSelectOptions?.status) {
      tagOptions.push(tagsSelectOptions.status);
    }
    return tagOptions;
  }, [hideCategoryTags, tagsSelectOptions]);

  const taggedItems = useMemo(
    () => getTaggedItems({ objects, useSuggestedTags }),
    [objects, useSuggestedTags],
  );

  const saveTags = async () => {
    const promises = [];
    if (!disableTagsUpdate) {
      const tagsToAddGuids = selectedItems
        .filter((option) => !option.checkboxIndeterminate)
        .map((option) => option.value as string);

      const tagsToRemoveGuids = difference(
        taggedItems?.map((item) => item.tag.guid),
        selectedItems.map((option) => option.value as string),
      );

      const initialState = getInitialMap(objects || []);

      const finalState: TaggedItemsUpdateData[] = getFinalState(
        initialState,
        tagsToAddGuids,
        tagsToRemoveGuids,
      );

      if (finalState.length > 0) {
        promises.push(mutateTags({ replace_existing: true, tag_set: finalState }));
      }

      objects.forEach((obj) => {
        obj?.taggedItems?.forEach((taggedItem) => {
          if (taggedItem.kind === 'suggestion') {
            const selectedItem = selectedItems.find((item) => item.value === taggedItem.tag.guid);
            if (selectedItem && isSuggestedOption(selectedItem)) {
              promises.push(
                mutateTaggedItem({
                  httpClientUrl: `/tagged-items/${taggedItem.guid}/`,
                  kind: 'user-defined',
                }),
              );
            }
          }
        });
      });
    }

    onSave?.(selectedItems);

    await Promise.all(promises);
    if (promises.length > 0) {
      invalidateTagsDependenciesQueries();
      renderInfoToast('Tags Updated');
    }
  };

  const getClickOutsideExcludedTargets = () => {
    const createTamModalElement = document.getElementById('create-tag-modal');
    return createTamModalElement ? [createTamModalElement] : undefined;
  };

  const handleSelectedItemsChange = (value: SelectValue) => {
    setSelectedItems(value as Option[]);
  };

  const handleCreateNewTag = (newTag: TagModel) => {
    if (newTag) {
      setSelectedItems((prevSelectedItems) => [
        ...prevSelectedItems,
        { original: { guid: newTag.guid, tag: newTag }, value: newTag.guid } as Option,
      ]);
    }
  };

  useEffect(() => {
    const initialExpandedKeys: string[] = ['category_tag_container', 'status_tag_container'];

    const initialSelectedTags =
      taggedItems?.map((item) => {
        const tagIsAddedToAllObjectGuids = taggedItemsCounts?.[item.tag.guid] === objects?.length;

        item.tag.breadcrumbs?.forEach((breadcrumb) => {
          initialExpandedKeys.push(breadcrumb.targetGuid);
        });

        return {
          ...item.option,
          checkboxColor: item.kind === 'suggestion' ? 'pale-purple' : ('purple' as VariantColor),
          checkboxIndeterminate: !tagIsAddedToAllObjectGuids,
          original: item,
        };
      }) ?? [];

    setExpandedKeys(initialExpandedKeys);
    setSelectedItems(initialSelectedTags);
  }, [objects?.length, setExpandedKeys, setSelectedItems, taggedItems, taggedItemsCounts]);

  const isLoading = isMutateTaggedItemsLoading || isMutateTagsLoading;

  return (
    <Box alignItems="center" compDisplay="flex">
      <Select
        clearInputOnSelect={false}
        expandedKeys={expandedKeys}
        getClickOutsideExcludedTargets={getClickOutsideExcludedTargets}
        isMulti
        isTree
        maxWidth="100%"
        onChange={handleSelectedItemsChange}
        onClose={() => {
          saveTags();
        }}
        optionListMaxHeight="378px"
        options={options}
        popperConfigProps={{
          customPopperStyles: {
            width: `${TAGS_SELECT_WIDTH}px`,
          },
          fallbackPlacements: ['bottom-end', 'top-start', 'top-end'],
          ...popperConfigProps,
        }}
        renderCustomAnchor={({ anchorProps, anchorRef, isOpen }) =>
          isEditable ? (
            renderCustomAnchor?.({ anchorProps, anchorRef, isLoading, isOpen }) ?? (
              <Box ref={anchorRef} alignItems="center" compDisplay="flex" flexWrap="wrap" my={0.5}>
                <Tooltip action content="Add a tag">
                  <IconButton
                    {...anchorProps}
                    aria-label="edit tags"
                    backgroundColor={isOpen ? 'gray.100' : 'transparent'}
                    className={isLoading || isOpen ? '' : 'inline-button'}
                    data-testid="edit-tags-button"
                    disabled={isLoading}
                    {...iconButtonProps}
                  >
                    {isLoading ? (
                      <CircularLoader
                        borderWidth={2}
                        color={theme.colors.gray[500]}
                        compSize={1.75}
                      />
                    ) : (
                      <Icon color={theme.colors.v1.gray[400]} name="tag-outline" size="16px" />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            )
          ) : (
            <div />
          )
        }
        renderEmptyMessage={(searchInputValue) => (
          <EmptyMessage
            hideCategoryTags={hideCategoryTags}
            onCreateNewTag={handleCreateNewTag}
            searchInputValue={searchInputValue}
          />
        )}
        showSearchOnOptions
        showSelectAllButton={false}
        value={selectedItems}
      />
      {renderSelectedItems?.(selectedItems)}
    </Box>
  );
};

export default TagsSelect;
