import React, { useCallback, useMemo, useState } from 'react';
import { Cell } from 'react-table';

import type { TableauDataSourceModel } from '@api/tableau/TableauDataSourceModel';
import DateTime from '@components/DateTime';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import RelatedObjectsCountCell from '@components/Table/Cells/RelatedObjectsCountsCell';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import Tooltip from '@components/Tooltip';
import type { FilterOptions, FilterServiceInterface } from '@utils/filters';

export const DATA_SOURCE_TABLE_SEARCH_CONFIG: { [key: string]: keyof FilterOptions } = {
  description: 'search_description',
  name: 'search_name',
  tags: 'search_tags',
};

export const DATA_SOURCE_TABLE_SORT_CONFIG = {
  connectionMethod: 'connection_method',
  description: 'description',
  downstreamObjectsCounts: 'downstream_objects_total',
  lastUpdated: 'updated_at',
  name: 'name',
  popularity: 'popularity',
  sourceTablesCount: 'source_tables_count',
};

interface DataSourceTableProps {
  data:
    | {
        count: number;
        results: TableauDataSourceModel[];
      }
    | undefined;
  filterService: FilterServiceInterface;
  isDataSourceEditable: boolean;
  isLoading?: boolean;
  selectedRowIds: Record<string, boolean>;
  toggleAll?: (checked: boolean) => void;
  toggleItem?: (item: TableauDataSourceModel, checked: boolean) => void;
}

const DataSourceTable: React.FC<DataSourceTableProps> = ({
  data,
  filterService,
  isDataSourceEditable,
  isLoading,
  selectedRowIds,
  toggleAll,
  toggleItem,
}) => {
  const [isShowFilter, setShowFilter] = useState(false);
  const toggleFilter = useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);
  const { changePage, filter, initialTableSortState, search, sort } = filterService;
  const hiddenColumns = ['lastUpdated'];

  const columns: ColumnConfig<TableauDataSourceModel>[] = useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<TableauDataSourceModel>) => {
          const datasource = props.row.original;
          return (
            <LinkedCell
              {...props}
              item={datasource}
              itemName={datasource.name}
              showDataTypeTooltip
              showIcon
            />
          );
        },
        Header: `Name (${data?.count ?? 0})`,
        accessor: (d) => d.name,
        disableHiding: true,
        id: 'name',
        width: '130%',
      },
      {
        Cell: (props: Cell<TableauDataSourceModel>) => {
          const datasource = props.row.original;

          return (
            <DescriptionCell
              {...props}
              aiDescription={datasource.aiDescription}
              dataSourceType={datasource.dataTypes?.dataSourceType}
              description={datasource.description}
              descriptionSource={datasource.descriptionSource}
              guid={datasource.guid}
              ingestedDescription={datasource.ingestedDescription}
              isDataSourceEditable={isDataSourceEditable}
              name={datasource.name}
              richtextDescription={datasource.richtextDescription}
              suggestedDescription={datasource.suggestedDescription}
              userDescription={datasource.userDescription}
            />
          );
        },
        Header: 'Description',
        accessor: 'description',
        id: 'description',
        width: '140%',
      },
      {
        Cell: (props: Cell<TableauDataSourceModel>) => {
          const { row } = props;
          const datasource = row.original;

          return (
            <EditableTaggedItemCell
              {...props}
              isDataSourceEditable={isDataSourceEditable}
              obj={datasource}
            />
          );
        },
        Header: 'Tags',
        accessor: (d) => d.taggedItems,
        disableSortBy: true,
        id: 'tags',
        width: '130%',
      },
      {
        Header: 'Type',
        accessor: (d) => d.formattedConnectionMethod,
        disableFilters: true,
        id: 'connectionMethod',
        width: '130%',
      },
      {
        Cell: (props: Cell<TableauDataSourceModel>) => {
          const { row } = props;
          const datasource = row.original;
          return <DateTime datetime={datasource.updatedAt} />;
        },
        Header: 'Last Modified',
        accessor: (d) => d.updatedAt,
        disableFilters: true,
        id: 'lastUpdated',
        sortDescFirst: true,
        width: '115%',
      },
      {
        Cell: (props: Cell<TableauDataSourceModel>) => {
          const datasource = props.row.original;
          return datasource.upstreamObjectsCounts?.sourceTable;
        },
        Header: (
          <Tooltip content="Count of upstream data tables connected to this data source">
            <span>Source Tables</span>
          </Tooltip>
        ),
        accessor: (d) => d.upstreamObjectsCounts?.sourceTable,
        disableFilters: true,
        dropdownCheckboxLabel: 'Source Tables',
        id: 'sourceTablesCount',
        sortDescFirst: true,
        width: 150,
      },
      {
        Cell: ({ row: { original } }: Cell<TableauDataSourceModel>) => (
          <RelatedObjectsCountCell counts={original.downstreamObjectsCounts} />
        ),
        Header: (
          <Tooltip content="Count of downstream tables and dashboards from data lineage">
            <span>Downstream</span>
          </Tooltip>
        ),
        accessor: (d) => d.downstreamObjectsCounts?.total,
        disableFilters: true,
        dropdownCheckboxLabel: 'Downstream',
        id: 'downstreamObjectsCounts',
        sortDescFirst: true,
        width: 110,
      },
      {
        Cell: (props: Cell<TableauDataSourceModel>) => {
          const table = props.row.original;
          return <PopularityCell {...props} isTableauDataSource popularity={table.popularity} />;
        },
        Header: PopularityCellHeader,
        accessor: (d) => d?.popularity?.formattedPopularity,
        disableFilters: true,
        disableResizing: true,
        id: 'popularity',
        sortDescFirst: true,
        width: 120,
      },
    ],
    [data?.count, isDataSourceEditable],
  );

  const getRowId = useCallback((row: Partial<TableauDataSourceModel>) => row.guid!, []);
  const totalPages = data && filter.page_size ? Math.ceil(data.count / filter.page_size) : 1;

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        columns={columns}
        compact
        data={data?.results ?? []}
        getRowId={getRowId}
        initialState={{
          hiddenColumns,
          pageIndex: filter.page ? filter.page - 1 : 0,
          selectedRowIds,
          sortBy: initialTableSortState,
        }}
        loading={isLoading}
        manualFilters
        manualPagination
        manualSortBy
        rangeSelectConfig={{
          onCellCheckChange: toggleItem,
          onHeaderCheckChange: toggleAll,
        }}
        setFilters={search}
        setSortBy={sort}
        showFilter={isShowFilter}
        sortable
        stickyHeader
        toggleFilter={toggleFilter}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default React.memo<DataSourceTableProps>(DataSourceTable);
