import { DEFAULT_DOCS_URL } from '@constants';

import { DataSourceTypesType } from '@models/DataSourceCredentials';

export const USER_DOCS_URL: PartialRecord<DataSourceTypesType, string> = {
  airflow: `${DEFAULT_DOCS_URL}/integrations/apache-airflow`,
  bigquery: `${DEFAULT_DOCS_URL}/integrations/bigquery`,
  data_studio: `${DEFAULT_DOCS_URL}/integrations/data-studio`,
  databricks: `${DEFAULT_DOCS_URL}/integrations/databricks`,
  db2: `${DEFAULT_DOCS_URL}/integrations/db2`,
  dbt: `${DEFAULT_DOCS_URL}/integrations/dbt`,
  glue: `${DEFAULT_DOCS_URL}/integrations/aws-glue`,
  looker: `${DEFAULT_DOCS_URL}/integrations/looker`,
  metabase: `${DEFAULT_DOCS_URL}/integrations/metabase`,
  mode: `${DEFAULT_DOCS_URL}/integrations/mode`,
  mssql: `${DEFAULT_DOCS_URL}/integrations/mssql`,
  mysql: `${DEFAULT_DOCS_URL}/integrations/mysql`,
  oracle: `${DEFAULT_DOCS_URL}/integrations/oracle`,
  periscope: `${DEFAULT_DOCS_URL}/integrations/periscope`,
  postgres: `${DEFAULT_DOCS_URL}/integrations/postgres`,
  power_bi: `${DEFAULT_DOCS_URL}/integrations/powerbi`,
  quicksight: `${DEFAULT_DOCS_URL}/integrations/quicksight`,
  redshift: `${DEFAULT_DOCS_URL}/integrations/redshift`,
  salesforce: `${DEFAULT_DOCS_URL}/integrations/salesforce`,
  sigma: `${DEFAULT_DOCS_URL}/integrations/sigma-computing`,
  snowflake: `${DEFAULT_DOCS_URL}/integrations/snowflake`,
  tableau: `${DEFAULT_DOCS_URL}/integrations/tableau-server`,
  thoughtspot: `${DEFAULT_DOCS_URL}/integrations/thoughtspot`,
};

const getUserDocsUrl = (dataSourceType?: DataSourceTypesType) =>
  USER_DOCS_URL[dataSourceType!] ?? `${DEFAULT_DOCS_URL}/integrations`;

export default getUserDocsUrl;
