import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type * as CSS from 'csstype';
import type { HTMLAttributes } from 'react';

import Box from '@components/Box';
import type { StyledBoxProps } from '@components/Box/Box.styles';
import getColor, { GetColorProps } from '@styles/mixins/getColor';
import rootTheme from '@styles/theme';

type Variant = 'default' | 'clear' | 'outlined' | 'opaque';
type Size = 'default' | 'sm' | 'xs' | 'lg';

const sizes: Record<Size, string> = {
  default: '24px',
  lg: '32px',
  sm: '20px',
  xs: '14px',
};

const variants: Record<Variant, SerializedStyles> = {
  clear: css`
    color: ${rootTheme.colors.gray[800]};
  `,
  default: css`
    border-radius: 24px;
    color: ${rootTheme.colors.gray[800]};
  `,
  opaque: css`
    color: ${rootTheme.colors.gray[800]};
  `,
  outlined: css`
    border-radius: 3px;
    border-width: 1px;
    border-color: ${rootTheme.colors.gray[200]};
    color: ${rootTheme.colors.v1.gray[600]};
  `,
};

const variantsHover: Record<Variant, SerializedStyles> = {
  clear: css``,
  default: css`
    background-color: ${rootTheme.colors.gray[100]};
  `,
  opaque: css`
    background-color: ${rootTheme.colors.v1.primary[50]};
  `,
  outlined: css`
    border-color: ${rootTheme.colors.gray[300]};
    color: ${rootTheme.colors.v1.gray[800]};
  `,
};

export interface StyledIconButtonWrapperProps {
  backgroundColor?: GetColorProps;
  iconColor?: CSS.Property.Color;
  size?: Size;
  variant?: Variant;
}

export const StyledIconButtonWrapper = styled.span<StyledIconButtonWrapperProps>`
  align-items: center;
  display: flex;
  height: ${({ size = 'default' }) => sizes[size]};
  width: ${({ size = 'default' }) => sizes[size]};
  justify-content: center;
  transition: all 0.1s ease;
  background: ${({ backgroundColor = 'transparent' }) => getColor(backgroundColor)};
  border-radius: inherit;

  ${({ variant = 'default' }) => variants[variant]};
`;

export interface StyledIconButtonLabelProps {
  color?: CSS.Property.Color;
  fontSize?: CSS.Property.FontSize;
}

export const StyledIconButtonLabel = styled.span<StyledIconButtonLabelProps>`
  line-height: 0;
  transition: color 0.1s ease;
  color: ${({ color, theme }) => color ?? theme.colors.gray[500]};
  font-family: ${({ theme }) => theme.typography.fontFamilies.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
`;

export interface StyledIconButtonProps
  extends StyledBoxProps,
    Omit<HTMLAttributes<HTMLDivElement>, 'color'> {
  disabled?: boolean;
  hoverBackgroundColor?: GetColorProps;
  hoverColor?: GetColorProps;
  href?: string;
  rel?: string;
  reverse?: boolean;
  size?: Size;
  target?: string;
  variant?: Variant;
}

export const StyledIconButton = styled(Box)<StyledIconButtonProps>`
  align-items: center;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: all 0.1s ease;
  display: inline-flex;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : undefined)};
  height: ${({ size = 'default' }) => sizes[size]};
  font-size: ${({ fontSize, theme }) => fontSize ?? theme.typography.fontSizes.sm};
  justify-content: center;
  outline: none;
  gap: ${({ gap, theme }) => gap ?? theme.space(0.5)};
  padding: 0;
  position: relative;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : undefined)};
  user-select: none;

  &:hover {
    ${StyledIconButtonLabel} {
      color: ${({ hoverColor, theme }) =>
        hoverColor ? getColor(hoverColor) : theme.colors.primary};
    }

    ${StyledIconButtonWrapper} {
      ${({ hoverBackgroundColor, variant = 'default' }) =>
        hoverBackgroundColor
          ? `background-color: ${getColor(hoverBackgroundColor)};`
          : variantsHover[variant]};

      color: ${({ color, hoverColor }) => (hoverColor ? getColor(hoverColor) : color)};
    }
  }

  &:focus {
    outline: none;
  }
`;
