import React, { useCallback, useMemo, useState } from 'react';
import { Cell } from 'react-table';

import { LookMLViewModel } from '@api/lookML/LookMLViewModel';
import DescriptionCell from '@components/Table/Cells/DescriptionCell';
import EditableTaggedItemCell from '@components/Table/Cells/EditableTaggedItemCell';
import LinkedCell from '@components/Table/Cells/LinkedCell';
import PopularityCell from '@components/Table/Cells/PopularityCell';
import PopularityCellHeader from '@components/Table/Cells/PopularityCell/PopularityCellHeader';
import SearchHeader from '@components/Table/Cells/SearchHeader';
import UserCell from '@components/Table/Cells/UserCell';
import Table from '@components/Table/Table';
import type { ColumnConfig } from '@components/Table/Table/types';
import TableStyled from '@components/Table/TableStyled';
import useCustomAttributesColumns from '@hooks/useCustomAttributesColumns';
import { Filter } from '@utils';
import { getPopularityNormalized } from '@utils/popularity';

const INITIAL_SORT_STATE = [
  {
    desc: true,
    id: 'popularity',
  },
];

const HIDDEN_COLUMNS = ['businessOwner', 'technicalOwner'];

interface LookMLViewsTableProps {
  data?: LookMLViewModel[];
  filterService: Filter.FilterServiceInterface;
  isDataSourceEditable: boolean;
  itemCount?: number;
  selectedRowIds: { [guid: string]: boolean };
  setSelectedRowIds: React.Dispatch<React.SetStateAction<{}>>;
  totalPages: number;
}

const LookMLViewsTable: React.FC<LookMLViewsTableProps> = ({
  data,
  filterService,
  isDataSourceEditable,
  itemCount = 0,
  selectedRowIds,
  setSelectedRowIds,
  totalPages,
}) => {
  const [isShowFilter, setShowFilter] = useState(false);
  const toggleFilter = useCallback(() => setShowFilter((prev) => !prev), [setShowFilter]);
  const { changePage, filter, search, sort } = filterService;
  const customAttributesAssetType = 'looker,lookmlview,';

  const { customAttributesColumns } = useCustomAttributesColumns({
    assetType: customAttributesAssetType,
    isDataSourceEditable,
    tableData: data ?? [],
  });

  const columns: ColumnConfig<LookMLViewModel>[] = useMemo(
    () => [
      {
        Header: SearchHeader,
        disableFilters: true,
        disableResizing: true,
        disableSortBy: true,
        id: 'search',
        width: 32,
      },
      {
        Cell: (props: Cell<LookMLViewModel>) => {
          const view = props.row.original;
          return (
            <LinkedCell
              {...props}
              item={view}
              itemName={`${view.projectName}.${view.name}`}
              showDataTypeTooltip
              showIcon
            />
          );
        },
        Header: `View (${itemCount})`,
        accessor: 'name',
        disableHiding: true,
        id: 'name',
        width: '130%',
      },
      {
        Cell: (props: Cell<LookMLViewModel>) => {
          const view = props.row.original;
          return (
            <LinkedCell {...props} item={view} itemName={view.label ? `${view.label}` : ` `} />
          );
        },
        Header: `Name (${itemCount})`,
        accessor: 'label',
        id: 'label',
        width: '130%',
      },
      {
        Cell: (props: Cell<LookMLViewModel>) => {
          const view = props.row.original;

          return (
            <DescriptionCell
              {...props}
              aiDescription={view.aiDescription}
              dataSourceType={view.dataTypes?.dataSourceType}
              description={view.description}
              descriptionSource={view.descriptionSource}
              guid={view.guid}
              ingestedDescription={view.ingestedDescription}
              isDataSourceEditable={isDataSourceEditable}
              name={view.name}
              richtextDescription={view.richtextDescription}
              suggestedDescription={view.suggestedDescription}
              suggestedDescriptionSource={view.suggestedDescriptionSource}
              suggestedDescriptionSourceObj={view.suggestedDescriptionSourceObject}
              userDescription={view.userDescription}
            />
          );
        },
        Header: 'Description',
        accessor: 'description',
        id: 'description',
        width: '124.5%',
      },
      ...customAttributesColumns,
      {
        Cell: (props: Cell<LookMLViewModel>) => {
          const { row } = props;
          const view = row.original;
          return (
            <EditableTaggedItemCell
              {...props}
              isDataSourceEditable={isDataSourceEditable}
              obj={view}
            />
          );
        },
        Header: 'Tags',
        accessor: (d) => d.taggedItems,
        disableSortBy: true,
        id: 'tags',
        width: '120%',
      },
      {
        Cell: ({ row: { original } }: Cell<LookMLViewModel>) => (
          <UserCell user={original.businessOwner?.obj} />
        ),
        Header: 'Business Owner',
        accessor: (d) => d.businessOwner?.obj?.fullName,
        disableFilters: true,
        disableSortBy: true,
        id: 'businessOwner',
        width: '120%',
      },
      {
        Cell: ({ row: { original } }: Cell<LookMLViewModel>) => (
          <UserCell user={original.technicalOwner?.obj} />
        ),
        Header: 'Technical Owner',
        accessor: (d) => d.technicalOwner?.obj?.fullName,
        disableFilters: true,
        disableSortBy: true,
        id: 'technicalOwner',
        width: '120%',
      },
      {
        Cell: (props: Cell<LookMLViewModel>) => {
          const explore = props.row.original;
          return <PopularityCell {...props} popularity={explore.popularity} />;
        },
        Header: PopularityCellHeader,
        accessor: (d) => getPopularityNormalized(d?.popularity?.popularity),
        disableFilters: true,
        disableResizing: true,
        id: 'popularity',
        sortDescFirst: true,
        width: 120,
      },
    ],
    [itemCount, customAttributesColumns, isDataSourceEditable],
  );

  const getRowId = React.useCallback((row: Partial<LookMLViewModel>) => row.guid!, []);

  return (
    <TableStyled>
      <Table
        basic="very"
        changePage={changePage}
        className="table-full"
        columns={columns}
        compact
        data={data || []}
        getRowId={getRowId}
        initialState={{
          hiddenColumns: HIDDEN_COLUMNS,
          pageIndex: filter.page ? filter.page - 1 : 0,
          selectedRowIds,
          sortBy: INITIAL_SORT_STATE,
        }}
        loading={data === undefined}
        manualFilters
        manualPagination
        manualSortBy
        setFilters={search}
        setSelectedRowIds={setSelectedRowIds}
        setSortBy={sort}
        showFilter={isShowFilter}
        sortable
        stickyHeader
        toggleFilter={toggleFilter}
        totalPages={totalPages}
        unstackable
      />
    </TableStyled>
  );
};

export default React.memo<LookMLViewsTableProps>(LookMLViewsTable);
