import React, { useEffect, useState } from 'react';
import { RouterLink, useHistory, useLocation } from '@routing/router';

import Box from '@components/Box';
import Tooltip from '@components/Tooltip.v1';
import Icon from '@components/UI/Icon';
import Select from '@components/UI/Select/Select';
import type { Option, SelectValue } from '@components/UI/Select/types';
import { useUserContext } from '@context/User';
import theme from '@styles/theme';

import { SidebarItemAnchorProps, SidebarItemIcons } from '../AppMainSidebar.types';
import { useAppMainSidebar } from '../useAppMainSidebar';

import { StyledPrimarySidebarItemLabel, StyledPrimarySidebarLink } from './PrimarySidebar.styles';

const getItemIcons = ({ active, default: defaultIcon }: SidebarItemIcons) => {
  const activeIconElement =
    typeof active === 'string' ? <Icon color="currentcolor" name={active} size="20px" /> : active;

  const defaultIconElement =
    typeof defaultIcon === 'string' ? (
      <Icon color="currentcolor" name={defaultIcon} size="20px" />
    ) : (
      defaultIcon
    );

  return { activeIcon: activeIconElement, defaultIcon: defaultIconElement };
};

interface PrimarySidebarItemProps extends Pick<SidebarItemAnchorProps, 'isCollapsed' | 'onClick'> {
  externalLink?: boolean;
  getTo?: ({ currentPath }: { currentPath: string }) => string | undefined;
  icons?: SidebarItemIcons;
  id: string;
  isActive?: boolean;
  label?: string;
  options?: Option[];
}

const PrimarySidebarItem: React.FC<PrimarySidebarItemProps> = ({
  children,
  externalLink,
  getTo,
  icons,
  id,
  isActive,
  isCollapsed,
  label,
  onClick,
  options,
}) => {
  const [showMoreIsOpen, setShowMoreIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | undefined>();
  const { activeDataSourceGuid, activeMenuItemId } = useAppMainSidebar();

  const { dataSources } = useUserContext();
  const history = useHistory();
  const { pathname: currentPath } = useLocation();
  const to = getTo?.({ currentPath });
  const { activeIcon = null, defaultIcon = null } = icons ? getItemIcons(icons) : {};
  const internalLinkAs = to ? RouterLink : 'button';
  const linkAs = externalLink ? 'a' : internalLinkAs;
  const externalLinkProps = externalLink
    ? {
        href: to,
        rel: 'noopener noreferrer',
        target: '_blank',
      }
    : {};

  const handleShowMoreChange = (newValue: SelectValue) => {
    const [selected] = newValue as Option<string>[];
    setSelectedOption(selected);
    onClick?.({ dataSources, id: selected.value, push: history.push });
  };

  useEffect(() => {
    const selectedItem = options?.find((option) => option.value === activeDataSourceGuid);
    setSelectedOption(selectedItem);
  }, [activeDataSourceGuid, options]);

  const renderItem = (itemProps: { isActive?: boolean; onClick: () => void }) => {
    return (
      <Tooltip
        content={isCollapsed ? label : ''}
        followCursor={false}
        offset={[3, 8]}
        placement="right"
      >
        <StyledPrimarySidebarLink
          as={linkAs}
          data-testid="primary-sidebar-item"
          isActive={itemProps.isActive}
          isSidebarCollapsed={isCollapsed}
          onClick={itemProps.onClick}
          to={to}
          {...externalLinkProps}
        >
          <StyledPrimarySidebarItemLabel isCollapsed={isCollapsed}>
            {isActive ? activeIcon : defaultIcon}
            {label}
            {children}
          </StyledPrimarySidebarItemLabel>
        </StyledPrimarySidebarLink>
      </Tooltip>
    );
  };

  if (options) {
    return (
      <Select
        isDropdown
        isOpen={showMoreIsOpen}
        label="See more data sources"
        maxOptionsVisible={8}
        onChange={handleShowMoreChange}
        onClose={() => setShowMoreIsOpen(false)}
        options={options}
        optionsFitAnchorWidth={false}
        pl={0.5}
        popperConfigProps={{
          customPopperStyles: {
            borderRadius: theme.radius.lg,
            boxShadow: theme.shadow['4xl'],
            marginLeft: '14px',
            width: '200px',
          },
          placement: 'right-end',
        }}
        renderCustomAnchor={({ anchorProps, anchorRef, isOpen }) => (
          <Box ref={anchorRef} alignItems="center" compDisplay="flex" flexWrap="wrap" my={0.5}>
            <Box {...anchorProps} compWidth="100%">
              {renderItem({
                isActive:
                  isActive || isOpen || options.some((option) => option.value === activeMenuItemId),
                onClick: () => {
                  if (externalLink) return;
                  setShowMoreIsOpen((prev) => !prev);
                },
              })}
            </Box>
          </Box>
        )}
        value={selectedOption ? [selectedOption] : []}
      />
    );
  }

  return renderItem({
    isActive,
    onClick: () => {
      if (externalLink) return;
      onClick?.({ dataSources, id, push: history.push });
    },
  });
};

export default PrimarySidebarItem;
