import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import Checkbox from '@components/UI/Form/Checkbox';
import InputLabel from '@components/UI/Form/InputLabel';

import { StyledDropdown } from './FilterColumnButton.styles';
import { ColumnInstance } from './types';

interface FilterColumnsButtonProps {
  columns: ColumnInstance[];
  onClick: (id: ColumnInstance) => void;
}

const FilterColumnButton: React.FC<FilterColumnsButtonProps> = ({ columns, onClick }) => {
  return (
    <StyledDropdown
      aria-label="Select Visible Columns"
      className="filter-columns-dropdown"
      icon="filter"
      pointing="top right"
      upward={false}
    >
      <Dropdown.Menu>
        {columns
          ?.filter(
            (column) =>
              column.dropdownCheckboxLabel ||
              (typeof column.Header === 'string' && !column.disableHiding),
          )
          .map((column) => (
            <Dropdown.Item
              key={column.id}
              {...column.getToggleHiddenProps()}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <InputLabel
                alignItems="center"
                compDisplay="flex"
                compHeight="100%"
                compWidth="100%"
                fontSize="body1"
              >
                <Checkbox
                  checked={column.isVisible}
                  name={column.id}
                  onChange={() => {
                    column.toggleHidden();
                    onClick?.(column);
                  }}
                />
                {column.dropdownCheckboxLabel || column.Header}
              </InputLabel>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </StyledDropdown>
  );
};

export default FilterColumnButton;
