import React, { useEffect, useMemo, useRef } from 'react';
import { useCombobox, UseComboboxState, UseComboboxStateChange } from 'downshift';

import usePopper, { UsePopperProps } from '@components/Popper/usePopper';
import type { UseWindowScrollProps } from '@hooks/useWindowScroll';
import useWindowScroll from '@hooks/useWindowScroll';

import {
  flatExpandedChildren,
  getFiltered,
  isAllFilteredItemsSelected,
  isMatch,
  stringifyOptions,
} from './Select/Select.utils';
import { FieldState, IndexedOption, Option, SelectValue } from './types';
import useSelectState from './useSelectState';

export interface GetItemPropsParams {
  disabled?: boolean;
  index: number;
  item: Option;
}

export interface UseSelectProps extends Pick<UseWindowScrollProps, 'containerId'> {
  allowEmptySelection?: boolean;
  clearInputOnBlur?: boolean;
  clearInputOnSelect?: boolean;
  closeOnBlur?: boolean;
  closeOnScroll?: boolean;
  disableFiltering?: boolean;
  error?: boolean;
  expandedKeys?: string[];
  highlightFirstOnOptionsUpdate?: boolean;
  initialIsOpen?: boolean;
  isDisabled?: boolean;
  isDropdown?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  isOpen?: boolean;
  isTree?: boolean;
  onChange?: (newValue?: SelectValue) => void;
  onClose?: () => void;
  onInputBlur?: () => void;
  onSearchValueChange?: (text?: string) => void;
  options: Option[];
  optionsFitAnchorWidth?: boolean;
  popperConfigProps?: UsePopperProps;
  searchValue?: string;
  setIsOpen?: (isOpen?: boolean) => void;
  shouldBlockOnLoading?: boolean;
  value?: SelectValue;
}

const useSelect = ({
  allowEmptySelection,
  clearInputOnBlur,
  clearInputOnSelect,
  closeOnBlur = true,
  closeOnScroll = true,
  containerId,
  disableFiltering,
  error,
  expandedKeys,
  highlightFirstOnOptionsUpdate = true,
  initialIsOpen = false,
  isDisabled,
  isDropdown = false,
  isLoading,
  isMulti = false,
  isOpen: propIsOpen,
  isTree,
  onChange,
  onClose,
  onInputBlur,
  onSearchValueChange,
  options,
  optionsFitAnchorWidth = true,
  popperConfigProps,
  searchValue,
  setIsOpen,
  shouldBlockOnLoading = true,
  value,
}: UseSelectProps) => {
  const {
    addSelectedItem,
    flatOptions,
    getDropdownProps,
    removeSelectedItem,
    selectedItems,
    setFlatOptions,
    setSelectedItems,
    updateFlatOptions,
  } = useSelectState({ isDropdown, onChange, value });

  const {
    anchorProps,
    popperProps,
    update: updatePopper,
  } = usePopper({
    fallbackPlacements: ['bottom-end', 'top'],
    fitAnchorWidth: optionsFitAnchorWidth,
    offset: [0, 4],
    placement: 'bottom-start',
    strategy: 'fixed',
    ...popperConfigProps,
  });

  const indexedOptions: Array<IndexedOption> = useMemo(
    () => options.map((opt, id) => ({ ...opt, id })),
    [options],
  );

  const onInputValueChange = onSearchValueChange
    ? ({ inputValue: newValue }: { inputValue?: string }) => onSearchValueChange(newValue)
    : undefined;

  const selectItem = (
    changes: Partial<UseComboboxState<Option>>,
    state: UseComboboxState<Option>,
  ) => {
    if (changes.selectedItem || allowEmptySelection) {
      if (isMulti) {
        const filteredSelectedItems = selectedItems.filter(
          (el) => !isMatch(el, changes.selectedItem as Option),
        );

        const newItems =
          filteredSelectedItems.length === selectedItems.length
            ? [...selectedItems, changes.selectedItem as Option]
            : filteredSelectedItems;

        setSelectedItems(newItems);
      } else {
        setSelectedItems([changes.selectedItem as Option]);
      }
    }

    return {
      highlightedIndex: state.highlightedIndex,
      inputValue: clearInputOnSelect ? '' : state.inputValue,
      isOpen: isMulti ? state.isOpen : false,
      selectedItem: changes.selectedItem,
    };
  };

  const handleArrowKeyPress = (
    changes: Partial<UseComboboxState<Option>>,
    state: UseComboboxState<Option>,
    direction: 'up' | 'down',
  ) => {
    if (isTree) return changes;

    const { highlightedIndex: currentHighlightedIndex } = state;

    const filteredItems = disableFiltering
      ? indexedOptions
      : getFiltered({
          inputValue: state.inputValue,
          isTree,
          options: indexedOptions,
        });

    if (filteredItems.length === 0) return { ...changes, highlightedIndex: -1 };

    let nextHighlightedIndex = -1;

    if (direction === 'down') {
      nextHighlightedIndex = (
        filteredItems.find((el) => el.id > currentHighlightedIndex) ?? filteredItems[0]
      ).id;
    }

    if (direction === 'up') {
      const reversedItems = [...filteredItems].reverse();
      nextHighlightedIndex = (
        reversedItems.find((el) => el.id < currentHighlightedIndex) ?? filteredItems.slice(-1)[0]
      ).id;
    }

    return { ...changes, highlightedIndex: nextHighlightedIndex };
  };

  const handleStateChange = (state: UseComboboxStateChange<Option>) => {
    if (state.isOpen === false) {
      onClose?.();
    }
  };

  const {
    closeMenu,
    getInputProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    inputValue,
    isOpen,
    reset,
    setHighlightedIndex,
    setInputValue,
  } = useCombobox<Option>({
    initialIsOpen,
    inputValue: searchValue,
    isOpen: propIsOpen,
    /*
     * When the options are a tree we need to pass the flat options containing
     * the visible items of the tree so that the useCombobox knows the order of the
     * options and can make the items accessible via keyboard
     */
    items: isTree ? flatOptions : indexedOptions,
    onInputValueChange,
    onIsOpenChange: setIsOpen ? (changes) => setIsOpen(changes.isOpen) : undefined,
    onStateChange: handleStateChange,
    stateReducer(state, { changes, type }) {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return selectItem(changes, state);
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
        case useCombobox.stateChangeTypes.InputBlur:
          onInputBlur?.();
          return {
            ...changes,
            inputValue: clearInputOnBlur ? '' : state.inputValue,
            isOpen: closeOnBlur ? changes.isOpen : true,
          };
        case useCombobox.stateChangeTypes.InputKeyDownArrowDown:
          return handleArrowKeyPress(changes, state, 'down');
        case useCombobox.stateChangeTypes.InputKeyDownArrowUp:
          return handleArrowKeyPress(changes, state, 'up');
        default:
          return changes;
      }
    },
  });

  const filteredItems = React.useMemo(
    () =>
      disableFiltering
        ? indexedOptions
        : getFiltered({ inputValue, isTree, options: indexedOptions }),
    [disableFiltering, indexedOptions, inputValue, isTree],
  );

  const isAllSelected = isAllFilteredItemsSelected(selectedItems, filteredItems);

  useWindowScroll({ containerId, enabled: closeOnScroll, onScroll: closeMenu });

  const fieldState = useMemo(() => {
    const states = {
      disabled: isDisabled || (isLoading && shouldBlockOnLoading),
      error,
    };

    const currentState = Object.entries(states).find(([, current]) => Boolean(current));

    return (currentState?.[0] || 'default') as FieldState;
  }, [isDisabled, isLoading, shouldBlockOnLoading, error]);

  const dropdownProps = getDropdownProps(
    {
      disabled: fieldState === 'disabled',
      preventKeyAction: isOpen,
    },
    { suppressRefError: true },
  );

  const resetSelect = () => {
    reset();
    setSelectedItems([]);
  };

  useEffect(() => {
    if (isOpen) {
      updatePopper?.();
    }
  }, [inputValue, selectedItems, updatePopper, isOpen]);

  useEffect(() => {
    if (isTree) {
      let newFlatOptions: Option[] = [];
      filteredItems.forEach((root) => {
        if (root.children) {
          newFlatOptions = [...newFlatOptions, ...root.children];
        }
      });
      setFlatOptions?.(newFlatOptions);
    }
  }, [filteredItems, isTree, setFlatOptions]);

  const filteredItemsRef = useRef(filteredItems);
  filteredItemsRef.current = filteredItems;
  useEffect(() => {
    const buildInitialFlatOptions = () => {
      if (isTree) {
        const newFlatOptions: any = [];
        filteredItemsRef.current.forEach((item) => {
          newFlatOptions.push(...flatExpandedChildren(item, expandedKeys));
        });

        setFlatOptions?.(newFlatOptions);
      }
    };
    buildInitialFlatOptions();
  }, [expandedKeys, isTree, setFlatOptions]);

  const filteredItemsFlatString = stringifyOptions(filteredItems);

  useEffect(() => {
    if (highlightFirstOnOptionsUpdate) setHighlightedIndex(filteredItems?.[0]?.id ?? -1);
  }, [filteredItemsFlatString, highlightFirstOnOptionsUpdate, setHighlightedIndex]);

  return {
    addSelectedItem,
    closeMenu,
    dropdownProps,
    fieldState,
    filteredItems,
    flatOptions,
    getInputProps,
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    inputValue,
    isAllSelected,
    isOpen,
    popper: {
      anchorProps,
      popperProps,
    },
    removeSelectedItem,
    resetSelect,
    selectedItems,
    setInputValue,
    setSelectedItems,
    updateFlatOptions,
  };
};

export default useSelect;
