import React from 'react';
import { RouterLink, useLocation, useParams } from '@routing/router';

import { useFetchDocumentsHierarchy, usePatchDocument } from '@api/documents';
import invalidateCache from '@api/invalidateCache';
import { searchCacheKeys } from '@api/search';
import Box from '@components/Box';
import { renderErrorToast } from '@components/Toast';
import getUrl from '@components/Tree/getUrl';
import { renderIndentationLines } from '@components/Tree/Tree.v1';
import TreeNode from '@components/Tree/TreeNode.v1';
import Icon from '@components/UI/Icon';
import { useObjectPermissionsContext } from '@context/ObjectPermissions';
import useNewLayout from '@hooks/useNewLayout';
import fetchClient from '@lib/fetchClient';
import { HierarchyData } from '@models/HierarchyModel';
import theme from '@styles/theme';

import HierarchyLoadingSkeleton from '../HierarchyLoadingSkeleton';
import RoutedHierarchyTree from '../RoutedHierarchyTree';

import CreateDocMenu from './CreateDocMenu';
import DocumentsListMenu from './DocumentsListMenu';

const allMenuItem: HierarchyData = {
  breadcrumbLabelList: [],
  children: [],
  guid: 'all-docs',
  iconEl: <Icon color="currentColor" name="all-docs" size="16px" />,
  name: 'All Docs',
  objectType: 'menu-item',
  url: '/docs/tabs',
};

const DocumentsHierarchy: React.FC = () => {
  const { shouldUseNewLayout } = useNewLayout();
  const { data, isLoading } = useFetchDocumentsHierarchy();

  const { mutate } = usePatchDocument(undefined, {
    onError: (error) => {
      if (error?.status === 403) {
        renderErrorToast('Permission denied. Unable to move document.');
      }
    },
    onSuccess: (d) => {
      invalidateCache((keys) => [
        keys.metrics.all,
        keys.documents.all,
        keys.terms.all,
        keys.activity.all,
      ]);
      fetchClient.setQueryData(searchCacheKeys.searchItem(d.guid), d);
    },
  });
  const location = useLocation();
  const { isEditable } = useObjectPermissionsContext({ id: 'all-docs' });

  const { guid } = useParams<{ guid: string }>();

  if (isLoading) {
    return <HierarchyLoadingSkeleton />;
  }

  const baseHierarchyItems = shouldUseNewLayout ? [] : [allMenuItem];
  const treeData = data ? baseHierarchyItems.concat(data) : baseHierarchyItems;

  return (
    <>
      {isEditable && (
        <Box mb={1} px={shouldUseNewLayout ? 1.5 : 2}>
          <CreateDocMenu useNewLayout={shouldUseNewLayout} />
        </Box>
      )}
      {shouldUseNewLayout && <DocumentsListMenu />}
      <Box
        overflowX="hidden"
        overflowY="auto"
        pl={shouldUseNewLayout ? 0.5 : 0}
        pr={shouldUseNewLayout ? 0.25 : 0}
      >
        <RoutedHierarchyTree
          allowDragDrop={isEditable}
          defaultExpandedKeys={['all-docs', guid]}
          defaultSelectedKeys={location.pathname.includes(`/docs/tabs`) ? ['all-docs'] : [guid]}
          draggable={(node) => {
            if (['all-docs'].includes(node.key as string)) {
              return false;
            }

            return isEditable;
          }}
          keyAlias="guid"
          onDrop={({ dragNode, ordinal, parent }) => {
            mutate({
              httpClientUrl: `/documents/${dragNode.key}/`,
              ordinal,
              parent_hierarchy: parent !== 'all-docs' ? parent : null,
            });
          }}
          treeData={treeData}
          useNewLayout={shouldUseNewLayout}
        >
          {({ children, item }) => {
            const { level = 1, ordinal } = item;
            const normalizedLevel = level - 1;
            const newLayoutPaddingLeft = theme.space(1 + normalizedLevel * 2.5);
            const paddingLeft = shouldUseNewLayout ? newLayoutPaddingLeft : theme.space(level * 2);

            return (
              <TreeNode
                key={item.guid}
                icon={
                  <RouterLink to={getUrl(item) ?? '#'}>
                    {item.iconEl ?? (
                      <Icon
                        color="currentcolor"
                        name={item.dataTypes?.icons.dataType!}
                        size="16px"
                      />
                    )}
                  </RouterLink>
                }
                style={{
                  paddingLeft,
                  paddingRight: theme.space(1),
                }}
                title={
                  <RouterLink to={getUrl(item) ?? '#'}>
                    {shouldUseNewLayout &&
                      renderIndentationLines({
                        isFirstChild: ordinal === 0,
                        level: normalizedLevel,
                      })}
                    {item.name}
                  </RouterLink>
                }
              >
                {children}
              </TreeNode>
            );
          }}
        </RoutedHierarchyTree>
      </Box>
    </>
  );
};

export default DocumentsHierarchy;
