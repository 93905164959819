import React, { useState } from 'react';
import { dataSources as dataSourcesConfig } from '@configs/dataSources/config';

import DataSourceHierarchy from '@components/AppMainSidebar/Hierarchy/DataSourceHierarchy';
import { useUserContext } from '@context/User';
import { isBIType } from '@models/DataSourceCredentials';
import { alphabetically } from '@utils/sort';

import { SidebarItemConfig, SidebarSection } from '../AppMainSidebar.types';
import PrimarySidebarItem from '../PrimarySidebar/PrimarySidebarItem';

export const MAX_ITEMS_PRIMARY_SIDEBAR = 5;

const useDataSourcesPrimarySidebar = () => {
  const [primarySidebarDsGuid, setPrimarySidebarDsGuid] = useState<string | undefined>();
  const { dataSources = {} } = useUserContext();

  const sidebarDataSources = Object.values(dataSources)
    .filter((dataSource) => dataSource.shouldShow)
    .sort((a, b) => {
      if (a.dataTypes?.dataSourceType === b.dataTypes?.dataSourceType) {
        return alphabetically(true)(a.name.toLowerCase(), b.name.toLowerCase());
      }

      return alphabetically(true)(
        dataSourcesConfig[
          a.dataTypes?.dataSourceType as keyof typeof dataSourcesConfig
        ]?.text.toLowerCase(),
        dataSourcesConfig[
          b.dataTypes?.dataSourceType as keyof typeof dataSourcesConfig
        ]?.text.toLowerCase(),
      );
    });

  const uniqueDataSourceTypes = new Set<string>();
  const uniqueDataSourceTypesList: SidebarItemConfig[] = [];
  const seeMoreDataSourceList: SidebarItemConfig[] = [];

  sidebarDataSources.forEach((dataSource) => {
    const dataSourceType = dataSource.dataTypes?.dataSourceType;

    if (
      dataSourceType &&
      !uniqueDataSourceTypes.has(dataSourceType) &&
      uniqueDataSourceTypesList.length < MAX_ITEMS_PRIMARY_SIDEBAR
    ) {
      const dataSourceTypeOptions = sidebarDataSources
        .filter((item) => item.dataTypes?.dataSourceType === dataSource.dataTypes?.dataSourceType)
        .map((item) => ({
          icon: Object.values(dataSources ?? {}).find(
            (dataSourceItem) => item.guid === dataSourceItem.guid,
          )?.dataTypes?.icons.dataSource,
          text: item.name ?? '',
          value: item.guid,
        }));

      const options = dataSourceTypeOptions.length > 1 ? dataSourceTypeOptions : undefined;
      uniqueDataSourceTypesList.push({
        anchor: (props) => {
          return (
            <PrimarySidebarItem
              icons={{
                active: dataSource.dataTypes?.icons.dataSource!,
                default: dataSource.dataTypes?.icons.dataSource!,
              }}
              options={options}
              {...props}
            />
          );
        },
        children: (
          <DataSourceHierarchy
            primarySidebarDsGuid={primarySidebarDsGuid}
            type={isBIType(dataSource.dataTypes?.dataSourceType) ? 'bi' : 'dwh'}
          />
        ),
        id: dataSource.dataTypes?.dataSourceType!,
        label:
          dataSourcesConfig[dataSource.dataTypes?.dataSourceType as keyof typeof dataSourcesConfig]
            ?.text,
        onClick: (params) => {
          setPrimarySidebarDsGuid(options ? params.id : dataSource.guid);
        },
        options,
        secondarySidebarLabel: 'Data',
      });
      uniqueDataSourceTypes.add(dataSourceType);
    } else if (dataSourceType && !uniqueDataSourceTypes.has(dataSourceType)) {
      seeMoreDataSourceList.push({
        anchor: (props) => {
          return (
            <PrimarySidebarItem
              icons={{
                active: dataSource.dataTypes?.icons.dataSource!,
                default: dataSource.dataTypes?.icons.dataSource!,
              }}
              {...props}
            />
          );
        },
        children: (
          <DataSourceHierarchy
            primarySidebarDsGuid={primarySidebarDsGuid}
            type={isBIType(dataSource.dataTypes?.dataSourceType) ? 'bi' : 'dwh'}
          />
        ),
        id: dataSource.guid,
        label: dataSource.name,
        onClick: () => {
          setPrimarySidebarDsGuid(dataSource.guid);
        },
        secondarySidebarLabel: 'Data',
      });
    }
  });

  const dataSourcesSection: SidebarSection = {
    id: 'dataSources',
    items: [...uniqueDataSourceTypesList, ...seeMoreDataSourceList],
    maxItems: MAX_ITEMS_PRIMARY_SIDEBAR,
    order: 1,
  };

  return {
    dataSourcesSection,
  };
};

export default useDataSourcesPrimarySidebar;
