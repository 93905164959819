import React, { useRef } from 'react';

import { DataSourcesMutatePayload } from '@api/dataSources';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';
import { DataSourceCredentials } from '@models/DataSourceCredentials';

import useDataSourceMutation from '../useDataSourceMutation';

import { DataSourceFormProps } from './types';

const REQUIRED_FIELDS = ['name', 'accessTokenName', 'accessTokenSecret', 'baseUrl'] as const;

const TableauForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  dataType,
  name = '',
  onSuccess,
  renderBefore,
}) => {
  const segment = useSegmentContext();
  const lastCredentials = useRef<DataSourceCredentials | undefined>();

  const { error, isLoading, mutate } = useDataSourceMutation({
    dataSource,
    onSuccess: (ds) => {
      /**
       * Credentials has to be passed for tableau activity data step on frontend.
       * @todo [sc-13293] Fix it.
       */
      onSuccess({ ...ds, credentials: lastCredentials.current });
    },
  });

  const { handleChange, handleSubmit, values } = useForm({
    initialValues: {
      accessTokenName: '',
      accessTokenSecret: '',
      baseUrl: '',
      contentUrl: '',
      name: dataSource?.name || name,
    },
    onSubmit: (val) => {
      const payload = {} as DataSourcesMutatePayload;
      if (dataSource) {
        payload.guid = dataSource.guid;
        payload.type = dataSource.type;
      } else {
        payload.type = dataType;
      }
      payload.name = val.name;
      const newCredentials = {
        base_url: val.baseUrl,
        content_url: val.contentUrl,
        personal_access_token_name: val.accessTokenName,
        personal_access_token_secret: val.accessTokenSecret,
      };

      payload.credentials = newCredentials;
      lastCredentials.current = newCredentials;
      mutate(payload);

      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, { dataType });
    },
  });

  const isInvalid = REQUIRED_FIELDS.some((key) => Boolean(values[key]) === false);

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {renderBefore?.({ error, loading: isLoading })}
        <StyledLabel>
          Display Name
          <Input
            error={error?.data?.name}
            helperText={error?.data?.name}
            maxLength={50}
            name="name"
            onChange={handleChange}
            placeholder="Tableau"
            type="text"
            value={values.name}
          />
        </StyledLabel>
        <StyledLabel>
          Access Token Name
          <Input
            error={error?.data?.personal_access_token_name}
            helperText={error?.data?.personal_access_token_name}
            name="accessTokenName"
            onChange={handleChange}
            placeholder="Select_Star"
            type="text"
            value={values.accessTokenName}
          />
        </StyledLabel>
        <StyledLabel>
          Access Token Secret
          <Input
            error={error?.data?.personal_access_token_secret}
            helperText={error?.data?.personal_access_token_secret}
            name="accessTokenSecret"
            onChange={handleChange}
            placeholder="Secret"
            type="password"
            value={values.accessTokenSecret}
          />
        </StyledLabel>
        <StyledLabel>
          Base Url
          <Input
            error={error?.data?.base_url}
            helperText={error?.data?.base_url}
            name="baseUrl"
            onChange={handleChange}
            placeholder="https://10ax.online.tableau.com"
            type="text"
            value={values.baseUrl}
          />
        </StyledLabel>
        <StyledLabel>
          Site Id
          <Input
            error={error?.data?.content_url}
            helperText={error?.data?.content_url}
            name="contentUrl"
            onChange={handleChange}
            placeholder="siteid"
            type="text"
            value={values.contentUrl}
          />
        </StyledLabel>
      </StyledFormHorizontalLabelGrid>
      {children?.({ error, loading: isLoading || isInvalid })}
    </Form>
  );
};

export default TableauForm;
