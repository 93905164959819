import { Expose } from 'class-transformer';

class RelatedObjectsCountsModel {
  chart!: number;

  column!: number;

  dashboard!: number;

  dashboardelement!: number;

  datastudiochart!: number;

  cloudobject!: number;

  powerbidataset!: number;

  datastudiopage!: number;

  explore!: number;

  explorefield!: number;

  lookmlfield!: number;

  lookmlview!: number;

  metabasecolumn!: number;

  metabasetable!: number;

  powerbicolumn!: number;

  powerbitable!: number;

  reportquery!: number;

  table!: number;

  tableaucolumn!: number;

  tableaudatasource!: number;

  tableaufield!: number;

  tableautable!: number;

  thoughtspottable!: number;

  thoughtspotcolumn!: number;

  tableauview!: number;

  total!: number;

  page!: number;

  job!: number;

  bicolumn!: number;

  bidashboardelement!: number;

  bidataset!: number;

  notebook!: number;

  bitable!: number;

  @Expose({ name: 'impact_score' })
  impactScore!: number;

  @Expose({ name: 'source_table' })
  sourceTable!: number;

  @Expose({ name: 'total_all_depths' })
  totalAllDepths!: number;
}

export default RelatedObjectsCountsModel;
