import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from '@routing/router';
import { ErrorBoundary } from '@sentry/react';
import { ReactFlowProvider } from 'reactflow';

import { UsageTypeType } from '@api/lineage/types';
import Box from '@components/Box';
import UnexpectedError from '@components/Error/UnexpectedError';
import LineageExploreSidebar from '@components/LineageExplore/components/LineageExploreSidebar';
import LineageInteractionsWrapper from '@components/LineageExplore/components/LineageInteractionsWrapper';
import useCreateNodesEdges from '@components/LineageExplore/useCreateNodesEdges';
import {
  DEFAULT_INITIAL_POSITION,
  LineageExploreContextProvider,
  useLineageExplore,
} from '@components/LineageExplore/useLineageExplore';
import Text from '@components/Text';
import Modal from '@components/UI/Modal';
import { useModal } from '@context/Modal';
import theme from '@styles/theme';

import ControlBar from './components/ControlBar';
import LineageExploreGraph from './components/ExploreGraph';
import LineageExploreLoader from './components/ExploreLoader';
import LineageExploreErrorMessage from './components/LineageExploreErrorMessage';
import { LineageInteractions } from './LineageExplore.types';
import { getColumnIdFromKey, getNewUsageTypes } from './LineageExplore.utils';
import useColumnLevelLineage from './useColumnLevelLineage';
import useRemoveUsageType from './useRemoveUsageType';
import { UndoRedoContextProvider } from './useUndoRedo/UndoRedo.context';

interface LineageExploreProps {
  isError: boolean;
  isLoading: boolean;
  prevRoute: string;
}

const LineageExplore: React.FC<LineageExploreProps> = ({ isError, isLoading, prevRoute }) => {
  const history = useHistory();
  const location = useLocation<any>();

  const { loadingState, nodesById, setLoadingState } = useLineageExplore();

  const { MODAL_IDS, closeModal, openModal } = useModal();

  const handleClose = (_?: any, isUnmount?: boolean) => {
    closeModal(MODAL_IDS.explore);
    if (isUnmount) return;
    history.replace(`${prevRoute}${location.search}`, {
      ...location.state,
      scrollToTop: false,
    });
  };

  useEffect(() => {
    openModal(MODAL_IDS.explore);
  }, [MODAL_IDS.explore, openModal]);

  useEffect(() => {
    setLoadingState({ enabled: isLoading, type: 'initial' });
  }, [isLoading, setLoadingState]);

  let mainContent = null;

  if (isError) {
    mainContent = <LineageExploreErrorMessage />;
  } else if (Object.keys(nodesById).length === 0 && !loadingState.enabled) {
    mainContent = (
      <Box compDisplay="flex" compWidth="100%" justifyContent="center" mt={35}>
        <Text fontSize={theme.typography.fontSizes.h2}>No lineage detected.</Text>
      </Box>
    );
  } else {
    mainContent = <LineageExploreGraph />;
  }

  return (
    <Modal
      borderRadius={0}
      onClose={handleClose}
      renderContent={({ modalHandleClose }) => <ControlBar onClose={modalHandleClose} />}
      size="full"
    >
      <Box compDisplay="flex" compHeight="100vh">
        <LineageExploreSidebar />
        {mainContent}
        <LineageExploreLoader state={loadingState} />
      </Box>
    </Modal>
  );
};

export const LineageExploreContextsWrapper: React.FC<{
  initialColumnGuid?: string;
  initialTableGuid: string;
}> = ({ children, initialColumnGuid, initialTableGuid }) => {
  return (
    <ReactFlowProvider>
      <LineageExploreContextProvider
        initialColumnGuid={initialColumnGuid}
        initialTableGuid={initialTableGuid}
      >
        <UndoRedoContextProvider>
          <LineageInteractionsWrapper>{children}</LineageInteractionsWrapper>
        </UndoRedoContextProvider>
      </LineageExploreContextProvider>
    </ReactFlowProvider>
  );
};

const LineageExploreDataRequestWrapper: React.FC<{
  columnId?: string;
  prevRoute: string;
  tableId: string;
}> = ({ columnId: propsColumnId, prevRoute, tableId }) => {
  const [shouldRefreshCurrentLineage, setShouldRefreshCurrentLineage] = useState(false);
  const isInitialColumnDataCreated = useRef(false);
  const isDataSaved = useRef(false);
  const currentUsageTypes = useRef<Set<UsageTypeType>>(new Set());
  const currentRelevantLineageState = useRef<boolean>(false);
  const { createColumnNodes } = useColumnLevelLineage();
  const { removeUsageType } = useRemoveUsageType();

  const {
    columnLevelLineageRootKey,
    selectedUsageTypesState,
    setBiggestConflictEndPerStack,
    setEdgesById,
    setInitialPosition,
    setInputNodesById,
    setIsCollapseAllButtonEnabled,
    setIsColumnLevelLineage,
    setIsColumnLevelLoading,
    setNextUserInteraction,
    setNodesById,
    setNodesByStack,
    setStacksData,
    stackData,
    useRelevantLineage,
  } = useLineageExplore();

  const columnId = columnLevelLineageRootKey
    ? getColumnIdFromKey(columnLevelLineageRootKey)
    : propsColumnId;
  const columnKey = columnLevelLineageRootKey ?? (columnId ? `${tableId}/${columnId}` : undefined);

  const newUsageTypes = useMemo(
    () => getNewUsageTypes(currentUsageTypes.current, selectedUsageTypesState.usageTypes),
    [selectedUsageTypesState],
  );

  const shouldRecalculateRelevantLineage = useMemo(
    () => currentRelevantLineageState.current !== useRelevantLineage,
    [useRelevantLineage],
  );

  const {
    biggestConflictEndPerStack,
    columnInputNodesById,
    edgesById: initialEdgesById,
    inputNodesById,
    isFetchColumnLevelLoading,
    isFetchLineageError,
    isFetchLineageLoading,
    nodesById: initialNodesById,
    nodesByStack: initialNodesByStack,
    stacksData: initialStacksData,
  } = useCreateNodesEdges({
    columnGuid: propsColumnId,
    enabled: newUsageTypes.length > 0 || shouldRecalculateRelevantLineage,
    tableGuid: tableId,
    usageTypes: selectedUsageTypesState.usageTypes,
    useRelevantLineage,
  });

  const prepareLineageUpdate = () => {
    setIsColumnLevelLineage(false);
    isDataSaved.current = false;
    isInitialColumnDataCreated.current = false;
    currentUsageTypes.current = new Set(selectedUsageTypesState.usageTypes);
  };

  useEffect(() => {
    if (!selectedUsageTypesState.shouldNotRecalculate) {
      if (newUsageTypes.length > 0) {
        prepareLineageUpdate();

        if (newUsageTypes.length === 1 && newUsageTypes[0] === 'none') {
          setShouldRefreshCurrentLineage((current) => !current);
        }
      } else if (isDataSaved.current) {
        setIsCollapseAllButtonEnabled(true);
        removeUsageType({
          columnKey,
          tableGuid: tableId,
          usageTypes: selectedUsageTypesState.usageTypes,
        });
        currentUsageTypes.current = new Set(selectedUsageTypesState.usageTypes);
      }
    }
  }, [selectedUsageTypesState, newUsageTypes.length]);

  useEffect(() => {
    if (shouldRecalculateRelevantLineage) {
      prepareLineageUpdate();
      setShouldRefreshCurrentLineage((current) => !current);
      currentRelevantLineageState.current = useRelevantLineage;
    }
  }, [shouldRecalculateRelevantLineage, useRelevantLineage]);

  useEffect(() => {
    if (!isDataSaved.current && !isFetchLineageLoading) {
      setInitialPosition(DEFAULT_INITIAL_POSITION);
      setInputNodesById(inputNodesById);
      setNodesByStack(initialNodesByStack);
      setStacksData(initialStacksData, { fitView: !columnKey });
      setNodesById(initialNodesById);
      setEdgesById(initialEdgesById);
      setBiggestConflictEndPerStack(biggestConflictEndPerStack);
      setIsCollapseAllButtonEnabled(true);

      isDataSaved.current = true;

      if (columnKey) {
        setNextUserInteraction({
          payload: {
            nodeKey: columnKey,
          },
          type: LineageInteractions.ToggleColumnLevelLineage,
        });
      }
    }
  }, [
    shouldRefreshCurrentLineage,
    setIsCollapseAllButtonEnabled,
    setInitialPosition,
    initialNodesByStack,
    setNextUserInteraction,
    inputNodesById,
    isFetchLineageLoading,
    isFetchColumnLevelLoading,
    setIsColumnLevelLoading,
    initialStacksData,
    initialNodesById,
    initialEdgesById,
    biggestConflictEndPerStack,
    setInputNodesById,
    setNodesByStack,
    setStacksData,
    setNodesById,
    setEdgesById,
    setBiggestConflictEndPerStack,
    columnKey,
    stackData,
    columnLevelLineageRootKey,
  ]);

  useEffect(() => {
    /*
     * Calculates columns only if there is data in the stackData.
     * It means the table lineage has been loaded.
     */
    if (
      isDataSaved.current &&
      initialStacksData &&
      !isInitialColumnDataCreated.current &&
      columnInputNodesById &&
      tableId
    ) {
      isInitialColumnDataCreated.current = true;
      createColumnNodes({
        columnInputNodesById,
        fitView: !columnLevelLineageRootKey,
        removeEdgesWithNoUsages: selectedUsageTypesState.usageTypes.every(
          (usage) => usage !== 'none',
        ),
        stacksData: initialStacksData,
        startingTableId: tableId,
      });
      setIsColumnLevelLoading(false);

      if (columnLevelLineageRootKey) {
        setNextUserInteraction({
          payload: {
            nodeKey: columnLevelLineageRootKey,
          },
          type: LineageInteractions.ToggleColumnLevelLineage,
        });
      }
    }
  }, [
    initialStacksData,
    columnInputNodesById,
    createColumnNodes,
    tableId,
    columnLevelLineageRootKey,
    setNextUserInteraction,
    shouldRefreshCurrentLineage,
    selectedUsageTypesState.usageTypes,
    setIsColumnLevelLoading,
  ]);

  return (
    <LineageExplore
      isError={isFetchLineageError}
      isLoading={
        isFetchLineageLoading || (shouldRecalculateRelevantLineage && isFetchColumnLevelLoading)
      }
      prevRoute={prevRoute}
    />
  );
};

const LineageExploreWithContexts: React.FC<{ prevRoute: string }> = ({ prevRoute }) => {
  const {
    columnGuid, // BI column page
    guid: tableGuid,
    itemId, // DWH column page
  } = useParams<{ columnGuid?: string; guid: string; itemId?: string }>();

  const columnId = itemId ?? columnGuid;

  return (
    <LineageExploreContextsWrapper initialColumnGuid={columnId} initialTableGuid={tableGuid}>
      <LineageExploreDataRequestWrapper
        columnId={columnId}
        prevRoute={prevRoute}
        tableId={tableGuid}
      />
    </LineageExploreContextsWrapper>
  );
};

const LineageExploreErrorBoundary: React.FC<{ prevRoute: string }> = ({ prevRoute }) => {
  return (
    <ErrorBoundary fallback={UnexpectedError}>
      <LineageExploreWithContexts prevRoute={prevRoute} />
    </ErrorBoundary>
  );
};

export default LineageExploreErrorBoundary;
