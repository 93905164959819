import { dataSources } from '@configs/dataSources/config';

import { filterIndex } from '@api/search/indexes';
import type { FilterIndex, QuickSearchIndexFilter } from '@api/search/types';
import type { IconProps } from '@components/UI/Icon';

export type DatasourceSubtabV1 = {
  indexes: FilterIndex[];
  name: string;
};

export type DatasourceTabV1 = {
  filters: QuickSearchIndexFilter[];
  icon?: IconProps['name'];
  name: string;
  subtabs?: DatasourceSubtabV1[];
};

/*
 * The order of the items in the tabConfigKeys also defines the tabs' render order.
 * See the tabConfigDefault function further below.
 */
export const tabConfigKeys = [
  'all',
  'document',
  'database',
  'looker',
  'mode',
  'sigma',
  'powerbi',
  dataSources.quicksight.value,
  dataSources.hex.value,
  'metabase',
  'data-studio',
  'tableau',
  'periscope',
  'thoughtspot',
  'select-star',
] as const;

type TabConfigKey = (typeof tabConfigKeys)[number];
export type TabConfig = Record<TabConfigKey, DatasourceTabV1>;

export const tabConfig: TabConfig = {
  all: {
    filters: [{ indexes: filterIndex as any }],
    name: 'All',
  },
  'data-studio': {
    filters: [{ indexes: ['data_studio_dashboards'] }],
    icon: 'data-studio',
    name: 'Looker Studio',
    subtabs: [{ indexes: ['data_studio_dashboards'], name: 'Dashboard' }],
  },
  database: {
    filters: [
      {
        indexes: [
          'columns',
          'databases',
          'dbt-tests',
          'schemata',
          'tables',
          'databricks_folders',
          'databricks_notebooks',
          'jobs',
        ],
      },
    ],
    icon: 'database',
    name: 'Data',
    subtabs: [
      { indexes: ['databases'], name: 'Database' },
      { indexes: ['schemata'], name: 'Schema' },
      { indexes: ['tables'], name: 'Table' },
      { indexes: ['columns'], name: 'Column' },
      { indexes: ['dbt-tests'], name: 'dbt Test' },
      { indexes: ['databricks_folders'], name: 'Folder' },
      { indexes: ['databricks_notebooks'], name: 'Notebook' },
      { indexes: ['jobs'], name: 'DAG' },
    ],
  },
  document: {
    filters: [{ indexes: ['documents', 'metrics', 'terms'] }],
    icon: 'document',
    name: 'Docs',
    subtabs: [
      { indexes: ['documents'], name: 'Page' },
      { indexes: ['metrics'], name: 'Metric' },
      { indexes: ['terms'], name: 'Glossary Term' },
    ],
  },
  hex: {
    filters: [{ indexes: ['hex_folders', 'hex_dashboards'] }],
    icon: dataSources.hex.value,
    name: dataSources.hex.text,
    subtabs: [
      { indexes: ['hex_folders'], name: 'Folder' },
      { indexes: ['hex_dashboards'], name: 'Dashboard' },
    ],
  },
  looker: {
    filters: [
      {
        indexes: [
          'dashboard_elements',
          'explore_fields',
          'explores',
          'looker_dashboards',
          'looker_looks',
          'lookml_views',
          'dashboard_elements',
          'looker_folders',
          'lookml_fields',
        ],
      },
    ],
    icon: 'looker',
    name: 'Looker',
    subtabs: [
      { indexes: ['looker_folders'], name: 'Folder' },
      { indexes: ['looker_dashboards'], name: 'Dashboard' },
      { indexes: ['lookml_fields'], name: 'Field' },
      { indexes: ['dashboard_elements'], name: 'Tile' },
      { indexes: ['looker_looks'], name: 'Look' },
      { indexes: ['lookml_views'], name: 'Views' },
      { indexes: ['dashboard_elements'], name: 'Dashboard Tile' },
      { indexes: ['explores'], name: 'Explore' },
      { indexes: ['explore_fields'], name: 'Explore Field' },
    ],
  },
  metabase: {
    filters: [
      {
        indexes: ['metabase_cards', 'metabase_dashboards'],
      },
    ],
    icon: 'metabase',
    name: 'Metabase',
    subtabs: [
      { indexes: ['metabase_dashboards'], name: 'Dashboard' },
      { indexes: ['metabase_cards'], name: 'Card' },
    ],
  },
  mode: {
    filters: [
      {
        indexes: ['mode_reports', 'mode_spaces'],
      },
    ],
    icon: 'mode',
    name: 'Mode',
    subtabs: [
      { indexes: ['mode_reports'], name: 'Report' },
      { indexes: ['mode_spaces'], name: 'Collection' },
    ],
  },
  periscope: {
    filters: [
      {
        indexes: ['periscope_dashboards', 'periscope_folders'],
      },
    ],
    icon: 'periscope',
    name: 'Periscope',
    subtabs: [
      { indexes: ['periscope_dashboards'], name: 'Periscope Dashboards' },
      { indexes: ['periscope_folders'], name: 'Periscope Folders' },
    ],
  },
  powerbi: {
    filters: [
      {
        indexes: [
          'power_bi_columns',
          'power_bi_dashboards',
          'power_bi_datasets',
          'power_bi_dataflows',
          'power_bi_folders',
          'power_bi_pages',
          'power_bi_reports',
          'power_bi_tiles',
        ],
      },
    ],
    icon: 'powerbi',
    name: 'Power BI',
    subtabs: [
      { indexes: ['power_bi_folders'], name: 'Folder' },
      { indexes: ['power_bi_reports'], name: 'Report' },
      { indexes: ['power_bi_dashboards'], name: 'Dashboard' },
      { indexes: ['power_bi_tiles'], name: 'Tile' },
      { indexes: ['power_bi_pages'], name: 'Page' },
      { indexes: ['power_bi_datasets'], name: 'Dataset' },
      { indexes: ['power_bi_dataflows'], name: 'Dataflow' },
      { indexes: ['power_bi_columns'], name: 'Field' },
    ],
  },
  quicksight: {
    filters: [
      {
        indexes: [
          'quicksight_folders',
          'quicksight_reports',
          'quicksight_dashboards',
          'quicksight_bidashboardelements',
          'quicksight_bidatasets',
          'quicksight_bicolumns',
        ],
      },
    ],
    icon: dataSources.quicksight.value,
    name: dataSources.quicksight.text,
    subtabs: [
      { indexes: ['quicksight_folders'], name: 'Folder' },
      { indexes: ['quicksight_reports'], name: 'Analysis' },
      { indexes: ['quicksight_dashboards'], name: 'Dashboard' },
      { indexes: ['quicksight_bidashboardelements'], name: 'Visual' },
      { indexes: ['quicksight_bidatasets'], name: 'Dataset' },
      { indexes: ['quicksight_bicolumns'], name: 'Field' },
    ],
  },
  'select-star': {
    filters: [
      {
        indexes: ['comments', 'tags', 'teams', 'users'],
      },
    ],
    icon: 'select-star-colored',
    name: 'Select Star',
    subtabs: [
      { indexes: ['comments'], name: 'Discussion' },
      { indexes: ['users'], name: 'User' },
      { indexes: ['teams'], name: 'Teams' },
      { indexes: ['tags'], name: 'Tags' },
    ],
  },
  sigma: {
    filters: [
      {
        indexes: ['sigma_dashboards'],
      },
    ],
    icon: 'sigma',
    name: 'Sigma',
    subtabs: [{ indexes: ['sigma_dashboards'], name: 'Workbook' }],
  },
  tableau: {
    filters: [
      {
        indexes: [
          'tableau_data_sources',
          'tableau_fields',
          'tableau_projects',
          'tableau_views',
          'tableau_workbooks',
        ],
      },
    ],
    icon: 'tableau',
    name: 'Tableau',
    subtabs: [
      { indexes: ['tableau_workbooks'], name: 'Workbook' },
      { indexes: ['tableau_projects'], name: 'Project' },
      { indexes: ['tableau_views'], name: 'Dashboard / View Field' },
      { indexes: ['tableau_fields'], name: 'Field' },
      { indexes: ['tableau_data_sources'], name: 'Data Source' },
    ],
  },
  thoughtspot: {
    filters: [
      {
        indexes: [
          'thoughtspot_answers',
          'thoughtspot_columns',
          'thoughtspot_data_folders',
          'thoughtspot_liveboards',
          'thoughtspot_tables',
          'thoughtspot_views',
          'thoughtspot_worksheets',
        ],
      },
    ],
    icon: 'thoughtspot',
    name: 'Thoughtspot',
    subtabs: [
      { indexes: ['thoughtspot_data_folders'], name: 'Folder' },
      { indexes: ['thoughtspot_answers'], name: 'Answer' },
      { indexes: ['thoughtspot_liveboards'], name: 'Liveboard' },
      { indexes: ['thoughtspot_tables'], name: 'Table' },
      { indexes: ['thoughtspot_views'], name: 'View' },
      { indexes: ['thoughtspot_worksheets'], name: 'Worksheet' },
      { indexes: ['thoughtspot_columns'], name: 'Field' },
    ],
  },
};

export const tabConfigDefault: DatasourceTabV1[] = tabConfigKeys.map(
  (k) => tabConfig[k as TabConfigKey],
);

export default tabConfig;
