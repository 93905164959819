import React, { useState } from 'react';

import { DataSourcesMutatePayload } from '@api/dataSources';
import Alert from '@components/Alert';
import Box from '@components/Box';
import { USER_DOCS_URL } from '@components/DataSourceSetup/components/DataSourceAddStep/userDocsUrlConfig';
import { StyledText } from '@components/DataSourceSetup/components/LookMLProjectSetup/LookMLProjectSetup';
import {
  StyledFormHorizontalLabelGrid,
  StyledLabel,
} from '@components/DataSourceSetup/DataSourceSetup.styles';
import Form from '@components/Form';
import useForm from '@components/Form/useForm';
import Input from '@components/Input/Input.v1';
import Link from '@components/Link';
import { useSegmentContext } from '@context/Segment';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import useDataSourceMutation from '../useDataSourceMutation';

import { DataSourceFormProps } from './types';

const REQUIRED_FIELDS = ['name', 'account', 'user', 'password', 'warehouse'] as const;

const SnowflakeForm: React.FC<DataSourceFormProps> = ({
  children,
  dataSource,
  dataType,
  name = '',
  onSuccess,
  renderBefore,
}) => {
  const segment = useSegmentContext();
  const { error, isLoading, mutate } = useDataSourceMutation({
    dataSource,
    onSuccess,
  });

  const [isRoleWarningMessageShown, setRoleWarningMessage] = useState<boolean>(false);
  const { handleChange, handleSubmit, values } = useForm({
    initialValues: {
      account: '',
      name: dataSource?.name || name,
      password: '',
      role: '',
      user: '',
      warehouse: '',
    },
    onSubmit: (val) => {
      const payload = {} as DataSourcesMutatePayload;
      if (dataSource) {
        payload.guid = dataSource.guid;
        payload.type = dataSource.type;
      } else {
        payload.type = dataType;
      }
      payload.name = val.name;
      payload.credentials = {
        account: val.account,
        password: val.password,
        role: val.role,
        user: val.user,
        warehouse: val.warehouse,
      };
      mutate(payload);

      segment?.track(SegmentTrackEventName.CreateServiceAccountConnectButtonClicked, { dataType });
    },
  });

  const isInvalid = REQUIRED_FIELDS.some((key) => Boolean(values[key]) === false);

  return (
    <Form isLoading={isLoading} onSubmit={handleSubmit}>
      <StyledFormHorizontalLabelGrid>
        {renderBefore?.({ error, loading: isLoading })}
        <StyledLabel>
          Display Name
          <Input
            error={error?.data?.name}
            helperText={error?.data?.name}
            maxLength={50}
            name="name"
            onChange={handleChange}
            placeholder="Snowflake"
            type="text"
            value={values.name}
          />
        </StyledLabel>
        <StyledLabel>
          Account
          <Input
            error={error?.data?.account}
            helperText={error?.data?.account}
            name="account"
            onChange={handleChange}
            placeholder="account.us-east-1"
            type="text"
            value={values.account}
          />
        </StyledLabel>
        <StyledLabel>
          Username
          <Input
            error={error?.data?.user}
            helperText={error?.data?.user}
            name="user"
            onChange={handleChange}
            placeholder="Username"
            type="text"
            value={values.user}
          />
        </StyledLabel>
        <StyledLabel>
          Password
          <Input
            error={error?.data?.password}
            helperText={error?.data?.password}
            name="password"
            onChange={handleChange}
            placeholder="Password"
            type="password"
            value={values.password}
          />
        </StyledLabel>
        <StyledLabel>
          Role
          <Input
            error={error?.data?.role}
            helperText={error?.data?.role}
            name="role"
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
              if (!e.currentTarget.value.toLowerCase().includes('selectstar')) {
                setRoleWarningMessage(true);
              } else {
                setRoleWarningMessage(false);
              }
            }}
            onChange={handleChange}
            placeholder="'selectstar_role' by default"
            type="text"
            value={values.role}
          />
        </StyledLabel>
        {isRoleWarningMessageShown && (
          <Box gridColumn="1/3">
            <Alert>
              <StyledText as="span" display="inline">
                If you don’t have access to the system QUERY_HISTORY view, we can still load the
                metadata but we will not be able to generate your popularity and lineage correctly.
                &nbsp;
                <Link
                  fontSize="inherit"
                  href={USER_DOCS_URL.snowflake}
                  rel="noopener noreferrer"
                  target="_blank"
                  underline
                >
                  Read More
                </Link>
                .
              </StyledText>
            </Alert>
          </Box>
        )}
        <StyledLabel>
          Warehouse
          <Input
            error={error?.data?.warehouse}
            helperText={error?.data?.warehouse}
            name="warehouse"
            onChange={handleChange}
            placeholder="Warehouse"
            type="text"
            value={values.warehouse}
          />
        </StyledLabel>
      </StyledFormHorizontalLabelGrid>
      {children?.({ error, loading: isLoading || isInvalid })}
    </Form>
  );
};

export default SnowflakeForm;
