/* eslint-disable sort-keys-fix/sort-keys-fix */
// disabled since we want to render a specific order

export const warehouseSources = {
  snowflake: { text: 'Snowflake', value: 'snowflake' },
  bigquery: { text: 'BigQuery', value: 'bigquery' },
  redshift: { text: 'Redshift', value: 'redshift' },
  postgres: { text: 'PostgreSQL', value: 'postgres' },
  databricks: { text: 'Databricks', value: 'databricks' },
  glue: { text: 'AWS Glue', value: 'glue' },
  mssql: { text: 'MS SQL', value: 'mssql' },
  oracle: { text: 'Oracle', value: 'oracle' },
  mysql: { text: 'MySQL', value: 'mysql' },
  salesforce: { text: 'Salesforce', value: 'salesforce' },
  db2: { text: 'DB2', value: 'db2' },
  airflow: { text: 'Apache Airflow', value: 'airflow' },
} as const;

export const biSources = {
  tableau: { text: 'Tableau', value: 'tableau' },
  looker: { text: 'Looker', value: 'looker' },
  mode: { text: 'Mode', value: 'mode' },
  periscope: { text: 'Sisense', value: 'periscope' },
  sigma: { text: 'Sigma', value: 'sigma' },
  power_bi: { text: 'Power BI', value: 'power_bi' },
  thoughtspot: { text: 'ThoughtSpot', value: 'thoughtspot' },
  metabase: { text: 'Metabase', value: 'metabase' },
  data_studio: { text: 'Looker Studio', value: 'data_studio' },
  quicksight: { text: 'QuickSight', value: 'quicksight' },
  hex: { text: 'Hex', value: 'hex' },
} as const;

export const transformerTools = {
  dbt: { text: 'dbt', value: 'dbt' },
  monte_carlo: { text: 'Monte Carlo', value: 'monte_carlo' },
} as const;

export const monitors = {
  monte_carlo: { text: 'Monte Carlo', value: 'monte_carlo' },
} as const;

export const dataSources = {
  ...warehouseSources,
  ...transformerTools,
  ...biSources,
  ...monitors,
} as const;
