import { plainToClass } from 'class-transformer';

import { BreadcrumbModel } from '@api/breadcrumbs/BreadcrumbModel';
import { RawSearchData } from '@api/search/SearchModel';
import type { ObjectType } from '@api/types';
import { breadcrumbsToList } from '@components/Breadcrumbs';
import replaceAll from '@utils/replaceAll';
import { urlMap } from '@utils/routing';

const searchItemMap: { [key in ObjectType | string]: string } = {
  column: 'Column',
  data_studio_dashboard: 'Dashboard',
  database: 'Database',
  dsuser: 'DataSource User',
  schema: 'Schema',
  table: 'Table',
  user: 'User',
  view: 'View',
  // BI integrations maps below
  // eslint-disable-next-line sort-keys-fix/sort-keys-fix
  bifolder: 'BIFolder',
  card: 'Metabase Card',
  comment: 'Comment',
  dashboard: 'Dashboard',
  dashboardelement: 'Dashboard Tile',
  data_studio_folder: 'Report',
  explore: 'Looker Explore',
  explorefield: 'Looker Field',
  looker_look: 'Look',
  lookmlview: 'LookMLView',
  metabase_dashboard: 'Dashboard',
  metric: 'Metric',
  periscope_dashboard: 'Dashboard',
  report: 'Mode Report',
  sigma_dashboard: 'Workbook',
  space: 'Mode Collection',
  tableaudatasource: 'Tableau Data Source',
  tableaufield: 'Tableau Field',
  tableauview: 'Tableau View',
  tag: 'Tag',
  team: 'Team',
  tile: 'Power BI Tile',
  visual: 'Power BI Visual',
};

export const getTypeLabel = (el: RawSearchData) => {
  if (el.table_type) return searchItemMap[el.table_type];
  if (el.field_type && el.field_type in searchItemMap) return searchItemMap[el.field_type];
  if (el.object_type === 'reportquery' && el.data_type) return searchItemMap[el.data_type];
  return searchItemMap[el.object_type];
};

export const getLookupField = (el: RawSearchData) => {
  /*
   * Temporary workaround for getting Links for BI Folders
   * Because for BIFolder we have different URL patterns (mode vs looker)
   */
  if (el.object_type === 'bifolder' && el.field_type === 'space') {
    return 'spaces';
  }
  if (el.object_type === 'bifolder' && el.data_source_type === 'periscope') {
    return 'periscope_folder';
  }
  if (el.object_type === 'bifolder' && el.data_source_type === 'data_studio') {
    return 'data_studio_bi_folder';
  }
  if (el.object_type === 'dashboard' && el.data_source_type === 'periscope') {
    return 'periscope_dashboard';
  }
  if (el.object_type === 'dashboard' && el.data_source_type === 'metabase') {
    return 'metabase_dashboard';
  }
  if (el.object_type === 'dashboard' && el.data_source_type === 'data_studio') {
    return 'dashboard';
  }
  if (
    el.object_type === 'dashboard' &&
    el.data_type === 'look' &&
    el.data_source_type === 'looker'
  ) {
    return 'looker_look';
  }
  if (el.object_type === 'reportquery' && el.data_source_type === 'metabase') {
    return 'metabase_card';
  }
  if (
    el.object_type === 'reportquery' &&
    el.data_source_type === 'power_bi' &&
    el.data_type === 'tile'
  ) {
    return 'power_bi_card';
  }
  if (
    el.object_type === 'reportquery' &&
    el.data_source_type === 'power_bi' &&
    el.data_type === 'page'
  ) {
    return 'power_bi_page';
  }
  /*
   * Because comments don't have their own Url we need to find the correct page and Url to link to
   * based on the parent(comment target) object type.
   */
  if (el.object_type === 'comment') {
    return el.parent_object_type || '';
  }
  return el.object_type;
};

export const getLink = (el: RawSearchData) => {
  const lookupField = getLookupField(el);
  if (lookupField in urlMap) {
    const template = urlMap[lookupField as keyof typeof urlMap];

    if (el.object_type === 'dbttest') {
      return replaceAll(template, {
        guid: el.guid!,
        parentGuid: el?.parent_guid || el.table_guid!,
      });
    }
    if (el.object_type === 'comment') {
      return `${replaceAll(template, { guid: el.parent_guid! })}/discussion/${el.guid}`;
    }
    if (el.object_type === 'column') {
      return replaceAll(template, {
        guid: el.guid!,
        parentGuid: el?.parent_guid || el.table_guid!,
      });
    }
    if (el.object_type === 'explorefield') {
      return replaceAll(template, {
        guid: el.guid!,
        parentGuid: el?.parent_guid || el.explore_guid!,
      });
    }
    if (el.object_type === 'metric') {
      return replaceAll(template, { guid: el.guid! });
    }
    if (
      ['dashboardelement', 'lookmlfield', 'reportquery', 'tableauview', 'tableaufield'].includes(
        el.object_type,
      )
    ) {
      if (el.parent_guid) {
        return replaceAll(template, { guid: el.guid!, parentGuid: el.parent_guid! });
      }
      return undefined;
    }
    return template.replace('{guid}', el.guid);
  }

  return undefined;
};

export const getBreadcrumbList = (el: RawSearchData) => {
  if (el.object_type === 'metric' || el.object_type === 'document' || el.object_type === 'term') {
    const breadcrumbsList = breadcrumbsToList(plainToClass(BreadcrumbModel, el.breadcrumbs));
    breadcrumbsList?.unshift({
      label: 'All Docs',
      url: '/docs/tabs',
    });

    return breadcrumbsList;
  }

  return breadcrumbsToList(plainToClass(BreadcrumbModel, el.breadcrumbs));
};

export const getPath = (
  objectType: string,
  searchName?: string,
  name?: string,
  description?: string,
): string | undefined => {
  if (objectType === 'comment') {
    return description;
  }

  // Returned undefined if object does not have a path.
  if (['user', 'dsuser', 'team'].includes(objectType) || searchName === name) {
    return undefined;
  }

  const lastIndexOfName = searchName?.lastIndexOf(`.${name}`);

  // Return the search name minus that last occurence of name to get the path. Then replace dots with ' > '.
  return searchName?.slice(0, lastIndexOfName).split('.').join(' > ');
};
