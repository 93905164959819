import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { XYPosition } from 'reactflow';

import { AVAILABLE_USAGE_TYPES } from '@api/lineage/types';
import { useFetchMetadataById } from '@api/metadata';
import { SegmentTrackEventName } from '@context/Segment/Segment.types';

import {
  ExploreEdge,
  ExploreNode,
  InputNodesById,
  LineageInteractions,
  LineageLoadingState,
  LineageSidebarTabs,
  UserInteraction,
} from '../LineageExplore.types';
import {
  CreateStacksResult,
  EdgesById,
  NodesById,
  StackGroups,
} from '../useCreateNodesEdges/algorithm/types';
import useGetConfigQueryParams from '../useGetConfigQueryParams';
import useUserTracking from '../useUserTracking';

import {
  BiggestConflictEndPerStack,
  ContextDefaultValues,
  EnableColumnLevelLineageParams,
  LineageState,
  OneDirectionParseExceptions,
  SelectedNode,
  SetStacksDataOptions,
  SidebarOpeningOrigin,
  UsageTypesState,
} from './LineageExplore.context.types';

const DEFAULT_PREVIOUS_TABLE_LINEAGE_STATE = {
  biggestConflictEndPerStack: {},
  edgesById: {},
  initialPosition: { x: 0, y: 0 },
  inputNodesById: {},
  nodesById: {},
  nodesByStack: {},
  stackData: {
    edgesById: {},
    nodesById: {},
    stackGroups: {},
    stackedAt: {},
    startingTableId: '',
  },
};
const DEFAULT_SELECTED_USAGE_TYPES = AVAILABLE_USAGE_TYPES.filter((usage) => usage !== 'filter');
const DEFAULT_LOADING_STATE = { enabled: true, type: 'initial' } as LineageLoadingState;
export const DEFAULT_INITIAL_POSITION: XYPosition = { x: 0, y: 0 };
export const DEFAULT_SIDEBAR_TAB = LineageSidebarTabs.Overview;

const defaultContextValue: ContextDefaultValues = {
  biggestConflictEndPerStack: {},
  centeredTableKey: '',
  columnLevelLineageRootKey: undefined,
  createWaitingAction: () => {},
  disableColumnLevelLineage: () => {},
  edgesById: {},
  enableColumnLevelLineage: () => {},
  expandLineageModeAllState: undefined,
  focusedNodeKey: '',
  getAllParents: () => undefined,
  initialPosition: { x: 0, y: 0 },
  initialSelectedNodeGuid: undefined,
  initialSelectedNodeParentGuid: '',
  inputNodesById: {},
  isCollapseAllButtonEnabled: true,
  isColumnLevelLineage: false,
  isColumnLevelLoading: true,
  isFetchingExpandColumnLineageModeAll: false,
  isLoadingSelectedNode: undefined,
  loadingState: DEFAULT_LOADING_STATE,
  nextUserInteraction: { current: undefined },
  nodesById: {},
  nodesByStack: {},
  oneDirectionParseExceptions: {},
  openOverviewTab: () => {},
  previousTableLineageState: DEFAULT_PREVIOUS_TABLE_LINEAGE_STATE,
  selectedEdge: undefined,
  selectedNode: undefined,
  selectedSidebarTab: undefined,
  selectedUsageTypesState: { usageTypes: DEFAULT_SELECTED_USAGE_TYPES },
  setBiggestConflictEndPerStack: () => {},
  setCenteredTableKey: () => {},
  setEdgesById: () => {},
  setExpandLineageModeAllState: () => {},
  setFocusedNodeKey: () => {},
  setInitialPosition: () => undefined,
  setInputNodesById: () => {},
  setIsCollapseAllButtonEnabled: () => {},
  setIsColumnLevelLineage: () => {},
  setIsColumnLevelLoading: () => {},
  setIsFetchingExpandColumnLineageModeAll: () => {},
  setLoadingState: () => {},
  setNextUserInteraction: () => {},
  setNodesById: () => {},
  setNodesByStack: () => {},
  setOneDirectionParseExceptions: () => {},
  setPreviousTableLineageState: () => {},
  setSelectedEdge: () => {},
  setSelectedNode: () => {},
  setSelectedUsageTypesState: () => {},
  setSidebarTab: () => {},
  setStacksData: () => {},
  setUseRelevantLineage: () => {},
  stackData: undefined,
  useRelevantLineage: false,
};

const LineageExploreContext = createContext(defaultContextValue);
export const useLineageExplore = () => useContext(LineageExploreContext);

interface NodesEdgesProviderProps {
  children: React.ReactNode;
  initialColumnGuid?: string;
  initialTableGuid: string;
}

export const LineageExploreContextProvider = ({
  children,
  initialColumnGuid,
  initialTableGuid,
}: NodesEdgesProviderProps) => {
  const { track } = useUserTracking();
  const { shouldSidebarStartCollapsed } = useGetConfigQueryParams();

  const [useRelevantLineage, setUseRelevantLineage] = useState(false);
  const [centeredTableKey, setCenteredTableKey] = useState<string>('');
  const [isCollapseAllButtonEnabled, setIsCollapseAllButtonEnabled] = useState(true);
  const [isColumnLevelLineage, setIsColumnLevelLineage] = useState<boolean>(false);
  const [columnLevelLineageRootKey, setColumnLevelLineageRootKey] = useState<string | undefined>();
  const [isColumnLevelLoading, setIsColumnLevelLoading] = useState<boolean>(!initialColumnGuid);
  const [nodesById, setNodesById] = useState<NodesById>({});
  const [edgesById, setEdgesById] = useState<EdgesById>({});
  const [inputNodesById, setInputNodesById] = useState<InputNodesById | undefined>({});
  const [biggestConflictEndPerStack, setBiggestConflictEndPerStack] =
    useState<BiggestConflictEndPerStack>({});

  const [selectedUsageTypesState, setSelectedUsageTypesState] = useState<UsageTypesState>({
    usageTypes: DEFAULT_SELECTED_USAGE_TYPES,
  });
  const [nodesByStack, setNodesByStack] = useState<StackGroups>({});
  const [stackData, setStateStacksData] = useState<CreateStacksResult | undefined>();
  const [selectedNode, setSelectedNode] = useState<SelectedNode>({
    guid: initialColumnGuid ?? initialTableGuid,
    key: initialColumnGuid ? `${initialTableGuid}/${initialColumnGuid}` : initialTableGuid,
    metadata: undefined,
    type: initialColumnGuid ? 'column' : 'table',
  });
  const [selectedEdge, setSelectedEdge] = useState<ExploreEdge | undefined>();
  const [previousTableLineageState, setPreviousTableLineageState] = useState<LineageState>(
    DEFAULT_PREVIOUS_TABLE_LINEAGE_STATE,
  );
  const [focusedNodeKey, setFocusedNodeKey] = useState<string>('');
  const [initialPosition, setInitialPosition] = useState<XYPosition>(DEFAULT_INITIAL_POSITION);
  const nextUserInteraction = useRef<undefined | UserInteraction>();
  const [loadingState, setLoadingState] = useState<LineageLoadingState>(DEFAULT_LOADING_STATE);
  const [isFetchingExpandColumnLineageModeAll, setIsFetchingExpandColumnLineageModeAll] =
    useState(false);
  const [expandLineageModeAllState, setExpandLineageModeAllState] =
    useState<ContextDefaultValues['expandLineageModeAllState']>();
  const [selectedSidebarTab, setSelectedSidebarTab] = useState<LineageSidebarTabs | undefined>(
    shouldSidebarStartCollapsed ? undefined : DEFAULT_SIDEBAR_TAB,
  );
  const [oneDirectionParseExceptions, setOneDirectionParseExceptions] =
    useState<OneDirectionParseExceptions>({});
  const [shouldTriggerWaitingActionCallback, setShouldTriggerWaitingActionCallback] =
    useState(false);

  const initialSelectedNodeGuid = initialColumnGuid ?? initialTableGuid;
  const initialSelectedNodeParentGuid = initialTableGuid;

  const { data: selectedNodeMetadata, isLoading: isLoadingSelectedNode } = useFetchMetadataById(
    selectedNode.guid,
    {
      enabled: Boolean(selectedNode.guid),
    },
  );

  const setNextUserInteraction = useCallback((interaction?: UserInteraction) => {
    nextUserInteraction.current = interaction;
  }, []);

  const setStacksData = useCallback(
    (newStacksData: CreateStacksResult | undefined, options?: SetStacksDataOptions) => {
      const { fitView = true } = options ?? {};
      setStateStacksData((current) => {
        const currentStacksLength = Object.keys(current?.stackGroups ?? {}).length;
        const newStacksLength = Object.keys(newStacksData?.stackGroups ?? {}).length;
        if (newStacksLength < currentStacksLength && fitView) {
          setNextUserInteraction({ type: LineageInteractions.FitLineageView });
        }

        return newStacksData;
      });
    },
    [setNextUserInteraction],
  );

  const disableColumnLevelLineage = useCallback(() => {
    setIsColumnLevelLineage(false);
    setNodesById(previousTableLineageState.nodesById);
    setEdgesById(previousTableLineageState.edgesById);
    setInputNodesById(previousTableLineageState.inputNodesById);
    setStacksData(previousTableLineageState.stackData);
    setNodesByStack(previousTableLineageState.nodesByStack);
    setInitialPosition(previousTableLineageState.initialPosition);
    setBiggestConflictEndPerStack(previousTableLineageState.biggestConflictEndPerStack);
    setColumnLevelLineageRootKey(undefined);
    setNextUserInteraction({ type: LineageInteractions.FitLineageView });
  }, [previousTableLineageState, setNextUserInteraction, setStacksData]);

  const enableColumnLevelLineage = useCallback(
    (
      {
        biggestConflictEndPerStack: columnLevelBiggestConflictEndPerStack,
        edgesById: columnLevelEdgesById,
        initialPosition: columnLevelInitialPosition,
        inputNodesById: columnLevelInputNodesById,
        nodesById: columnLevelNodesById,
        nodesByStack: columnLevelNodesByStack,
        rootColumnKey,
        stackData: columnLevelStackData,
      }: EnableColumnLevelLineageParams,
      updatePreviousTableLineageState = true,
    ) => {
      setColumnLevelLineageRootKey(rootColumnKey);
      setIsColumnLevelLineage(true);
      if (updatePreviousTableLineageState) {
        setPreviousTableLineageState({
          biggestConflictEndPerStack,
          edgesById,
          initialPosition,
          inputNodesById,
          nodesById,
          nodesByStack,
          stackData,
        });
      }
      setInputNodesById(columnLevelInputNodesById);
      setStacksData(columnLevelStackData);
      setNodesByStack(columnLevelNodesByStack);
      setNodesById(columnLevelNodesById);
      setEdgesById(columnLevelEdgesById);
      setBiggestConflictEndPerStack(columnLevelBiggestConflictEndPerStack);
      setInitialPosition(columnLevelInitialPosition);
      setNextUserInteraction({ type: LineageInteractions.FitLineageView });
    },
    [
      biggestConflictEndPerStack,
      edgesById,
      initialPosition,
      inputNodesById,
      nodesById,
      nodesByStack,
      stackData,
      setStacksData,
      setNextUserInteraction,
    ],
  );

  const getAllParents = useCallback(
    (nodeKey: string, currentStacksData?: CreateStacksResult): ExploreNode[] => {
      const findParentNodes = (key: string, parents: ExploreNode[]): ExploreNode[] => {
        const parentNode = (currentStacksData ?? stackData)?.nodesById[key];
        if (!parentNode || !parentNode.parent) {
          return parents;
        }

        const parent = (currentStacksData ?? stackData)?.nodesById?.[parentNode.parent];
        if (!parent) {
          return parents;
        }

        return findParentNodes(parentNode.parent, [...parents, parent]);
      };
      return findParentNodes(nodeKey, []);
    },
    [stackData],
  );

  const setSidebarTab = useCallback(
    (sidebarTab?: LineageSidebarTabs) => {
      setSelectedSidebarTab((current) => {
        if (current === sidebarTab) {
          track(SegmentTrackEventName.LineageSidebarClosed, { tab: sidebarTab });
          return undefined;
        }
        track(SegmentTrackEventName.LineageSidebarOpened, {
          origin: SidebarOpeningOrigin.Button,
          tab: sidebarTab,
        });
        return sidebarTab;
      });
    },
    [track],
  );

  const openOverviewTab = useCallback(
    (origin: SidebarOpeningOrigin) => {
      setSelectedSidebarTab((current) => {
        if (!current) {
          track(SegmentTrackEventName.LineageSidebarOpened, {
            origin,
            tab: LineageSidebarTabs.Overview,
          });
          return LineageSidebarTabs.Overview;
        }

        return current;
      });
    },
    [track],
  );

  /*
   * The waitingActionRef is used to store a reference to a function that will be executed later.
   * When the user expands all up/downstream from a column, we dispatch two API requests: one with mode=column and another with mode=all.
   * The mode=column request will return first, displaying the column lineage. If the user clicks to load more data while the mode=all request is still pending,
   * we'll save the action in the waitingActionRef. Once the mode=all request returns, we'll execute the saved action.
   * The waitingActionRef stores a reference to a function because we need the most updated lineage data when executing the action.
   * If we store the action function directly, it would execute with outdated lineage data.
   */
  const waitingActionRef = useRef<React.RefObject<Function>>();
  const createWaitingAction = useCallback(
    (refAction: React.RefObject<Function>) => {
      setLoadingState({ enabled: true, type: 'generic-operation' });
      waitingActionRef.current = refAction;
    },
    [setLoadingState],
  );

  useEffect(() => {
    if (expandLineageModeAllState && !isFetchingExpandColumnLineageModeAll) {
      setInputNodesById(expandLineageModeAllState?.inputNodesById);
      setPreviousTableLineageState(
        expandLineageModeAllState?.previousLineageState ?? DEFAULT_PREVIOUS_TABLE_LINEAGE_STATE,
      );
      setExpandLineageModeAllState(undefined);
      setShouldTriggerWaitingActionCallback(true);
    }
  }, [expandLineageModeAllState, isFetchingExpandColumnLineageModeAll]);

  useEffect(() => {
    if (waitingActionRef.current && shouldTriggerWaitingActionCallback) {
      waitingActionRef.current.current?.();
      waitingActionRef.current = undefined;
      setShouldTriggerWaitingActionCallback(false);
    }
  }, [shouldTriggerWaitingActionCallback]);

  useEffect(() => {
    setSelectedNode((prevSelectedNode) => ({
      ...prevSelectedNode,
      metadata: selectedNodeMetadata,
    }));
  }, [selectedNodeMetadata]);

  const value = useMemo(
    () => ({
      biggestConflictEndPerStack,
      centeredTableKey,
      columnLevelLineageRootKey,
      createWaitingAction,
      disableColumnLevelLineage,
      edgesById,
      enableColumnLevelLineage,
      expandLineageModeAllState,
      focusedNodeKey,
      getAllParents,
      initialPosition,
      initialSelectedNodeGuid,
      initialSelectedNodeParentGuid,
      inputNodesById,
      isCollapseAllButtonEnabled,
      isColumnLevelLineage,
      isColumnLevelLoading,
      isFetchingExpandColumnLineageModeAll,
      isLoadingSelectedNode,
      loadingState,
      nextUserInteraction,
      nodesById,
      nodesByStack,
      oneDirectionParseExceptions,
      openOverviewTab,
      previousTableLineageState,
      selectedEdge,
      selectedNode,
      selectedSidebarTab,
      selectedUsageTypesState,
      setBiggestConflictEndPerStack,
      setCenteredTableKey,
      setEdgesById,
      setExpandLineageModeAllState,
      setFocusedNodeKey,
      setInitialPosition,
      setInputNodesById,
      setIsCollapseAllButtonEnabled,
      setIsColumnLevelLineage,
      setIsColumnLevelLoading,
      setIsFetchingExpandColumnLineageModeAll,
      setLoadingState,
      setNextUserInteraction,
      setNodesById,
      setNodesByStack,
      setOneDirectionParseExceptions,
      setPreviousTableLineageState,
      setSelectedEdge,
      setSelectedNode,
      setSelectedUsageTypesState,
      setSidebarTab,
      setStacksData,
      setUseRelevantLineage,
      stackData,
      useRelevantLineage,
    }),
    [
      expandLineageModeAllState,
      selectedEdge,
      setSelectedEdge,
      oneDirectionParseExceptions,
      setOneDirectionParseExceptions,
      biggestConflictEndPerStack,
      centeredTableKey,
      columnLevelLineageRootKey,
      disableColumnLevelLineage,
      setUseRelevantLineage,
      useRelevantLineage,
      edgesById,
      enableColumnLevelLineage,
      focusedNodeKey,
      getAllParents,
      initialPosition,
      initialSelectedNodeGuid,
      initialSelectedNodeParentGuid,
      inputNodesById,
      isColumnLevelLineage,
      isColumnLevelLoading,
      isLoadingSelectedNode,
      loadingState,
      nodesById,
      nodesByStack,
      openOverviewTab,
      previousTableLineageState,
      selectedNode,
      selectedSidebarTab,
      selectedUsageTypesState,
      isCollapseAllButtonEnabled,
      setNextUserInteraction,
      setSidebarTab,
      setStacksData,
      stackData,
      isFetchingExpandColumnLineageModeAll,
      setIsFetchingExpandColumnLineageModeAll,
      createWaitingAction,
    ],
  );

  return <LineageExploreContext.Provider value={value}>{children}</LineageExploreContext.Provider>;
};
