import styled from '@emotion/styled';

import Box from '@components/Box';
import NotApplicable from '@components/NotApplicable';
import rootTheme from '@styles/theme';

const getGridTemplate = (length: number) => {
  if (length < 5) {
    return {
      gridTemplateColumns: 'minmax(0, 1fr)',
    };
  }

  if (length < 9) {
    return {
      gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
    };
  }

  return {
    gridTemplateColumns: 'minmax(0, 1fr) minmax(0, 1fr)',
  };
};

interface StyledDetailsListProps {
  length: number;
}

export const StyledDetailsList = styled.div<StyledDetailsListProps>`
  display: grid;
  grid-template-columns: minmax(0, 1fr);

  ${NotApplicable} {
    font-size: ${({ theme }) => theme.typography.fontSizes.body2};
  }

  @container details-card (min-width: 600px) {
    column-gap: ${({ theme }) => theme.space(2)};
    ${({ length }) => getGridTemplate(length)}
  }
`;

export const StyledDetailsListColumn = styled.ul``;

export const StyledDetailsListItem = styled.li`
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  display: flex;
  gap: ${({ theme }) => theme.space(2)};
  justify-content: flex-start;
  max-width: 100%;
  line-height: 1.5rem;

  margin-bottom: ${({ theme }) => theme.space(0.5)};
`;

export const StyledDetailsListItemLabel = styled(Box)``;
StyledDetailsListItemLabel.defaultProps = {
  alignItems: 'center',
  color: 'gray.500',
  compDisplay: 'inline-flex',
  fontSize: 'body2',
  gap: 0.25,
  whiteSpace: 'nowrap',
};

export const StyledDetailsListItemValue = styled(Box)``;
StyledDetailsListItemValue.defaultProps = {
  color: rootTheme.colors.text,
  fontSize: 'body2',
};
