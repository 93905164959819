import { Expose } from 'class-transformer';

import type { UseAvatarInitialsEnum } from '@api/openapi.generated';

import filterDisabledIntegrations from './utils/filterDisabledIntegrations';

export interface OrganizationSettingsData {
  lineageMaxDepth: number;
  separateSchemata: boolean;
  showColumnCategoryTags: boolean;
  showPreviews: boolean;
  showSchemataPage: boolean;
  showSidebarSearch: boolean;
  useAvatarInitials: UseAvatarInitialsEnum;
  useClientApi: boolean;
  useDiscussions: boolean;
  useDownstreamNotifications: boolean;
  useGroupLabel: boolean;
  useMetrics: boolean;
}

export class OrganizationSettings {
  constructor() {
    this.filterDisabledIntegrations = this.filterDisabledIntegrations.bind(this);
  }

  @Expose({ name: 'automated_pii' })
  automatedPii!: boolean;

  @Expose({ name: 'show_previews' })
  showPreviews!: boolean;

  @Expose({ name: 'lineage_max_depth' })
  lineageMaxDepth!: number;

  @Expose({ name: 'separate_schemata' })
  separateSchemata!: boolean;

  @Expose({ name: 'show_sidebar_search' })
  showSidebarSearch!: boolean;

  @Expose({ name: 'show_schemata_page' })
  showSchemataPage!: boolean;

  // Show category tags for DWH columns when it's true
  @Expose({ name: 'show_column_category_tags' })
  showColumnCategoryTags!: boolean;

  @Expose({ name: 'use_group_label' })
  useGroupLabel!: boolean;

  @Expose({ name: 'use_discussions' })
  useDiscussions!: boolean;

  @Expose({ name: 'use_metrics' })
  useMetrics!: boolean;

  @Expose({ name: 'use_client_api' })
  useClientApi!: boolean;

  @Expose({ name: 'use_documents' })
  useDocuments!: boolean;

  @Expose({ name: 'use_rbac' })
  useRbac: boolean = false;

  @Expose({ name: 'use_suggestion_propagation' })
  // description propagation
  useSuggestionPropagation: boolean = false;

  @Expose({ name: 'use_suggested_tags' })
  useSuggestedTags: boolean = false;

  @Expose({ name: 'use_schema_change_notification' })
  useSchemaChangeNotification: boolean = false;

  @Expose({ name: 'use_show_more_button' })
  useShowMoreButton!: boolean;

  @Expose({ name: 'enable_max_level_in_lineage_tags' })
  enableMaxLevelInLineageTags!: boolean;

  @Expose({ name: 'enable_cost_analysis' })
  enableCostAnalysis!: boolean;

  @Expose({ name: 'use_ai_descriptions' })
  useAiDescriptions!: boolean;

  @Expose({ name: 'use_custom_attributes_search' })
  useCustomAttributesSearch!: boolean;

  @Expose({ name: 'use_similar_tables_v2' })
  useSimilarTablesV2: boolean = false;

  @Expose({ name: 'use_downstream_notifications' })
  useDownstreamNotifications!: boolean;

  @Expose({ name: 'use_llm_chatbot' })
  useLlmChatBot!: boolean;

  @Expose({ name: 'use_databricks_notebook' })
  useDatabricksNotebook!: boolean;

  @Expose({ name: 'use_data_quality_tab' })
  useDataQualityTab!: boolean;

  @Expose({ name: 'use_airflow' })
  useAirflow!: boolean;

  @Expose({ name: 'use_hex' })
  useHex!: boolean;

  @Expose({ name: 'use_data_quality_upstream_issues' })
  useDataQualityUpstreamIssues!: boolean;

  @Expose({ name: 'use_relevant_lineage' })
  useRelevantLineage!: boolean;

  @Expose({ name: 'use_avatar_initials' })
  useAvatarInitials!: UseAvatarInitialsEnum;

  @Expose({ name: 'use_new_layout' })
  useNewLayout!: boolean;

  @Expose({ name: 'use_impact_score' })
  useImpactScore!: boolean;

  filterDisabledIntegrations(option: { value: string }) {
    return filterDisabledIntegrations(option, this);
  }
}
