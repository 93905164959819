import React from 'react';

import Box, { StyledBoxProps } from '@components/Box';
import formatNumber from '@utils/formatNumber';

export interface NumberBadgeProps extends StyledBoxProps {
  role?: string;
  roundMax?: number;
  value?: number;
  variant?: 'default' | 'sm';
}

const styles = {
  default: {
    compHeight: '22px',
    px: 1,
  },
  sm: {
    compHeight: '20px',
    px: 1,
  },
};

const NumberBadge: React.FC<NumberBadgeProps> = ({
  role,
  roundMax = 99,
  value = 0,
  variant = 'default',
  ...rest
}) => {
  return (
    <Box
      alignItems="center"
      backgroundColor="gray.100"
      borderRadius="2xl"
      color="gray.700"
      compDisplay="flex"
      compHeight={styles[variant].compHeight}
      fontSize="sm"
      fontWeight="medium"
      lineHeight="normal"
      px={styles[variant].px}
      role={role}
      textAlign="center"
      {...rest}
    >
      {formatNumber({ roundMax, value })}
    </Box>
  );
};

export default NumberBadge;
