import React from 'react';
import { RouterLink, useLocation } from '@routing/router';

import Icon, { IconProps } from '@components/UI/Icon';

import { StyledDocumentsListMenu, StyledDocumentsListMenuItem } from './DocumentsListMenu.styles';

interface MenuItem {
  icon: IconProps['name'];
  id: string;
  label: string;
  level?: number;
  url: string;
}

const MENU_ITEMS: MenuItem[] = [
  {
    icon: 'all-docs',
    id: 'all-docs',
    label: 'All Docs',
    url: '/docs/tabs/all',
  },
  {
    icon: 'document',
    id: 'pages',
    label: 'Pages',
    level: 1,
    url: '/docs/tabs/documents',
  },
  {
    icon: 'metric',
    id: 'metrics',
    label: 'Metrics',
    level: 1,
    url: '/docs/tabs/metrics',
  },
  {
    icon: 'term',
    id: 'business-glossary',
    label: 'Business Glossary',
    level: 1,
    url: '/docs/tabs/glossary',
  },
];

const DocumentsListMenu = () => {
  const { pathname } = useLocation();

  return (
    <StyledDocumentsListMenu>
      {MENU_ITEMS.map((item) => {
        const { icon, id, label, level, url } = item;
        const isSelected = pathname === url;
        return (
          <StyledDocumentsListMenuItem
            key={id}
            aria-selected={isSelected}
            as={RouterLink}
            isSelected={isSelected}
            level={level}
            to={url}
          >
            <Icon color="currentcolor" name={icon} size="16px" />
            {label}
          </StyledDocumentsListMenuItem>
        );
      })}
    </StyledDocumentsListMenu>
  );
};

export default DocumentsListMenu;
