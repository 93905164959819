import React from 'react';

import type { CheckboxProps } from '@components/UI/Form/Checkbox';

import { StyledTree } from './Tree.v2.styles';
import type { TreeNodeProps } from './TreeNode.v2';
import TreeNode from './TreeNode.v2';
import type { GetKeyType, TreeNodeType } from './types';
import { TreeKey } from './types';
import useHighlighted from './useHighlighted';
import useLoadData from './useLoadData';

export interface ExpandData {
  parentKey: string;
}

interface TreeProps<T>
  extends Pick<
    TreeNodeProps,
    | 'expandOnNodeClick'
    | 'hoverBackgroundColor'
    | 'hoverFontWeight'
    | 'hoverLetterSpacing'
    | 'showIndentationLines'
    | 'isFlatHierarchy'
  > {
  allowCaret?: (node: TreeNodeType<T>, expandData: ExpandData) => boolean;
  allowDataLoad?: (node: TreeNodeType<T>, expandData: ExpandData) => boolean;
  checkboxProps?: (node: TreeNodeType<T>) => CheckboxProps;
  className?: string;
  disableHover?: (node: TreeNodeType<T>) => boolean;
  enableHighlight?: ((node: TreeNodeType<T>) => boolean) | boolean;
  expandedKeys?: TreeKey[];
  getKey: GetKeyType<T>;
  /*
   *  getNodeExtraProps must return props that will passed to the node element.
   *  You can use it to add new dynamic props to the node, like props related to keyboard navigation.
   */
  getNodeExtraProps?: (node: TreeNodeType<T>) => any;
  highlightedKeys?: TreeKey[];
  offset?: (level: number, node: TreeNodeType<T>) => number;
  onCaretClick?: (node: TreeNodeType<T>, isExpanded: boolean) => void;
  onDataLoad?: (node: TreeNodeType<T>) => Promise<any>;
  onFilterDataLoad?: (node: any, key: string) => boolean;
  onNodeClick?: (node: TreeNodeType<T>) => void;
  onNodeMount?: (node: TreeNodeType<T>) => void;
  renderLabel: (node: TreeNodeType<T>, isCaretVisible?: boolean) => React.ReactNode;
  showCheckbox?: (node: TreeNodeType<T>) => boolean;
  treeData?: T[];
  useNewLayout?: boolean;
}

const Tree = <T,>({
  allowCaret,
  allowDataLoad = () => true,
  checkboxProps,
  className,
  disableHover,
  enableHighlight = true,
  expandOnNodeClick,
  expandedKeys = [],
  getKey,
  getNodeExtraProps,
  highlightedKeys = [],
  hoverBackgroundColor,
  hoverFontWeight,
  hoverLetterSpacing,
  isFlatHierarchy,
  offset,
  onCaretClick,
  onDataLoad,
  onNodeClick,
  onNodeMount,
  renderLabel,
  showCheckbox,
  showIndentationLines,
  treeData = [],
  useNewLayout,
}: TreeProps<T>) => {
  const [highlighted, setHighlighted] = useHighlighted(highlightedKeys);
  const { handleDataLoad, loadedTreeData, loadingNodes } = useLoadData<T>({
    getKey,
    onDataLoad,
  });

  const canHighlight = (node: TreeNodeType<T>) => {
    return typeof enableHighlight === 'function' ? enableHighlight?.(node) : () => enableHighlight;
  };

  const handleOnExpand = (node: TreeNodeType<T>, expanded: boolean, expandData: ExpandData) => {
    if (allowDataLoad?.(node, expandData) && expanded) {
      handleDataLoad?.(node);
    }
  };

  const handleNodeClick = (node: TreeNodeType<T>) => {
    if (canHighlight(node) && !showCheckbox) {
      setHighlighted((prev) => [...prev, node.key]);
    }
    onNodeClick?.(node);
  };

  const parseNodes = (nodes: T[], { level = 0, parentKey = '' } = {}) => {
    if (!nodes) {
      return null;
    }

    return nodes?.map?.((item, index) => {
      const key = getKey(item);
      const node: TreeNodeType<T> = {
        index,
        key,
        level,
        original: item,
      };
      const hasChildren = node.original.children && node.original.children.length > 0;
      const children = hasChildren ? node.original.children : loadedTreeData[key];
      const isCaretVisible = allowCaret?.(node, { parentKey }) ?? hasChildren;

      const sharedProps: TreeNodeProps = {
        checkboxProps: checkboxProps?.(node),
        disableHover: disableHover?.(node),
        expandOnNodeClick,
        hoverBackgroundColor,
        hoverFontWeight,
        hoverLetterSpacing,
        isCaretVisible,
        isDefaultExpanded: expandedKeys.includes(key),
        isFirstChild: index === 0,
        isFlatHierarchy,
        isHighlighted: !showCheckbox?.(node)
          ? canHighlight(node) && highlighted.includes(key)
          : false,
        isLastChild: index === nodes.length - 1,
        isLoading: loadingNodes[key],
        label: renderLabel(node, isCaretVisible),
        level,
        nodeExtraProps: getNodeExtraProps?.(node),
        offset: () => offset?.(level, node),
        onCaretClick: (isExpanded) => onCaretClick?.(node, isExpanded),
        onExpand: (expanded) => handleOnExpand(node, expanded, { parentKey }),
        onMount: () => onNodeMount?.(node),
        onNodeClick: () => handleNodeClick(node),
        showCheckbox: showCheckbox?.(node),
        showIndentationLines,
      };

      if (children) {
        return (
          <TreeNode {...sharedProps} key={key} useNewLayout={useNewLayout}>
            {parseNodes(children, { level: level + 1, parentKey: node.key })}
          </TreeNode>
        );
      }

      return <TreeNode {...sharedProps} key={key} useNewLayout={useNewLayout} />;
    });
  };

  return (
    <StyledTree className={className} role="tree" useNewLayout={useNewLayout}>
      {parseNodes(treeData)}
    </StyledTree>
  );
};

export default React.memo<TreeProps<any>>(Tree);
