import styled from '@emotion/styled';

import { StyledDocumentsListMenuItem } from '../DocumentsHierarchy/DocumentsListMenu/DocumentsListMenu.styles';

export const StyledTagsHierarchyAllTagsButton = styled(StyledDocumentsListMenuItem)`
  height: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  margin-left: ${({ theme }) => theme.space(1)};
`;

StyledTagsHierarchyAllTagsButton.defaultProps = {
  ...StyledDocumentsListMenuItem.defaultProps,
};
