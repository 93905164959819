import React, { useMemo } from 'react';
import type { DataTypesConfig } from '@configs/dataTypes/types';
import { isUndefined } from 'lodash';

import { DashboardTypes } from '@api/dashboards/DashboardTypes';
import { useFetchMetadataComments } from '@api/metadata';
import BiDashboardElementsTab from '@components/TabContent/BiDashboardElementsTab';
import DashboardTiles from '@components/TabContent/DashboardTiles/DashboardTiles';
import useDataQualityTab from '@components/TabContent/DataQualityTab/useDataQualityTab';
import DiscussionTab from '@components/TabContent/DiscussionTab';
import QueriesTab from '@components/TabContent/QueriesTab';
import TabbedLineage from '@components/TabContent/TabbedLineage';
import TableauViewsTab from '@components/TabContent/TableauViewsTab/TableauViewsTab';
import TopUsersTab from '@components/TabContent/TopUsersTab';
import type { TabRoutesConfig } from '@components/TabsRouter/TabsRouter';
import { useMetadataObjectContext } from '@context/MetadataObject';
import { useUserContext } from '@context/User';
import MetadataTabsRouter from '@pages/MetadataObjectPage/MetadataTabsRouter';
import wrapString from '@utils/wrapString';

import type { TabContentProps } from '../types';

interface DashboardsTabContentProps extends TabContentProps {}

const DashboardsTabContent: React.FC<DashboardsTabContentProps> = ({
  data,
  guid,
  overviewTabConfig,
}) => {
  const { data: commentsData } = useFetchMetadataComments(guid);
  const { dataSourceGuid } = useMetadataObjectContext();
  const { dataSources, organization, user } = useUserContext();
  const { dataQualityTabConfig } = useDataQualityTab({ guid, objectType: 'dashboard' });
  const isDataSourceEditable = Boolean(dataSources?.[dataSourceGuid ?? '']?.settings?.isEditable);
  const isThoughtspot = data?.dataTypes?.dataSourceType === 'thoughtspot';
  const hiddenTopUsersByDsType = ['thoughtspot'];
  const userDefaultTab = user?.settings.defaultTableDashboardTab;

  const dataTabDefaultConfig = userDefaultTab === 'columns_charts';

  const dataTab = useMemo(() => {
    const TAB_CONFIG: DataTypesConfig<TabRoutesConfig | null> = {
      hex: { default: null },
      quicksight: {
        default: {
          // eslint-disable-next-line react/no-unstable-nested-components
          component: () => (
            <BiDashboardElementsTab guid={guid} isDataSourceEditable={isDataSourceEditable} />
          ),
          default: dataTabDefaultConfig,
          label: 'Visuals',
          route: '/visuals',
        },
      },
    };
    const dataTabsMap = {
      [DashboardTypes.metabase_dashboard]: {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => (
          <QueriesTab
            dataType={data?.dataType}
            guid={guid}
            isDataSourceEditable={isDataSourceEditable}
          />
        ),
        default: dataTabDefaultConfig,
        label: 'Cards',
        route: '/cards',
      },
      [DashboardTypes.periscope_dashboard]: {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => (
          <QueriesTab
            dataType={data?.dataType}
            guid={guid}
            isDataSourceEditable={isDataSourceEditable}
          />
        ),
        default: dataTabDefaultConfig,
        label: 'Charts',
        route: '/charts',
      },
      [DashboardTypes.data_studio_dashboard]: {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => (
          <QueriesTab
            dataType={data?.dataType}
            guid={guid}
            isDataSourceEditable={isDataSourceEditable}
          />
        ),
        default: dataTabDefaultConfig,
        label: 'Pages',
        route: '/pages',
      },
      [DashboardTypes.dashboard]: {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => {
          return <DashboardTiles guid={guid} isDataSourceEditable={isDataSourceEditable} />;
        },
        default: dataTabDefaultConfig,
        label: 'Tiles',
        path: '/tiles/:itemId?',
        route: '/tiles',
      },
      [DashboardTypes.looker_look]: {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => {
          return <DashboardTiles guid={guid} isDataSourceEditable={isDataSourceEditable} />;
        },
        default: dataTabDefaultConfig,
        label: 'Tiles',
        path: '/tiles/:itemId?',
        route: '/tiles',
      },
      [DashboardTypes.power_bi_dashboard]: {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => {
          return (
            <QueriesTab
              dataType={data?.dataType}
              guid={guid}
              isDataSourceEditable={isDataSourceEditable}
            />
          );
        },
        default: dataTabDefaultConfig,
        label: 'Tiles',
        route: '/tiles',
      },
      [DashboardTypes.power_bi_report]: {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => (
          <QueriesTab
            dataType={data?.dataType}
            guid={guid}
            isDataSourceEditable={isDataSourceEditable}
          />
        ),
        default: dataTabDefaultConfig,
        label: 'Pages',
        route: '/pages',
      },
      [DashboardTypes.workbook]: {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => (
          <TableauViewsTab guid={guid} isDataSourceEditable={isDataSourceEditable} />
        ),
        default: dataTabDefaultConfig,
        label: 'Views',
        path: '/views/:itemId?',
        route: '/views',
      },
      [DashboardTypes.report]: {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => (
          <QueriesTab
            dataType={data?.dataType}
            guid={guid}
            isDataSourceEditable={isDataSourceEditable}
          />
        ),
        default: dataTabDefaultConfig,
        label: 'Queries',
        route: '/queries',
      },
      [DashboardTypes.sigma_dashboard]: {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => (
          <QueriesTab
            dataType={data?.dataType}
            guid={guid}
            isDataSourceEditable={isDataSourceEditable}
          />
        ),
        default: dataTabDefaultConfig,
        label: 'Elements',
        route: '/elements',
      },
      [DashboardTypes.answer]: {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => (
          <QueriesTab
            dataType={data?.dataTypes.dataType}
            guid={guid}
            isDataSourceEditable={isDataSourceEditable}
          />
        ),
        default: dataTabDefaultConfig,
        label: 'Fields',
        route: '/fields',
      },
      [DashboardTypes.liveboard]: {
        // eslint-disable-next-line react/no-unstable-nested-components
        component: () => (
          <QueriesTab
            dataType={data?.dataTypes.dataType}
            guid={guid}
            isDataSourceEditable={isDataSourceEditable}
          />
        ),
        default: dataTabDefaultConfig,
        label: 'Charts',
        route: '/charts',
      },
    };

    if (data?.dataTypes) {
      const customConfig = data.dataTypes.findConfig(TAB_CONFIG);
      if (isUndefined(customConfig)) {
        return dataTabsMap?.[data.dataTypes.dataType as DashboardTypes];
      }
      return customConfig;
    }

    return undefined;
  }, [data?.dataType, data?.dataTypes, guid, isDataSourceEditable, dataTabDefaultConfig]);

  const basicTabsRouterConfigs = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      component: () => (
        <TabbedLineage
          guid={guid}
          itemMetadata={data}
          showDownstream={!isThoughtspot}
          showDownstreamDashboards={false}
          sourceTables
        />
      ),
      contentOffset: 1.6,
      label: 'Lineage',
      route: '/lineage',
    },
    ...(!hiddenTopUsersByDsType.includes(data?.dataTypes?.dataSourceType)
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => <TopUsersTab guid={guid} itemMetadata={data} objectType="dashboard" />,
            label: 'Top Users',
            route: '/topusers',
          },
        ]
      : []),
    ...(organization?.settings?.useDataQualityTab && dataQualityTabConfig
      ? [dataQualityTabConfig]
      : []),
    ...(organization?.settings?.useDiscussions
      ? [
          {
            // eslint-disable-next-line react/no-unstable-nested-components
            component: () => <DiscussionTab enableNotifications guid={guid} />,
            contentOffset: 2,
            label: `Discussion ${wrapString(commentsData?.length)}`,
            path: '/discussion/:itemId?',
            route: '/discussion',
          },
        ]
      : []),
  ];

  const userSettingsOverviewTabConfig = {
    ...overviewTabConfig,
    default: userDefaultTab === 'overview' || !dataTab,
  };

  const tabsRouterConfig = dataTab
    ? [userSettingsOverviewTabConfig, dataTab, ...basicTabsRouterConfigs]
    : [userSettingsOverviewTabConfig, ...basicTabsRouterConfigs];

  return <MetadataTabsRouter config={tabsRouterConfig} />;
};

export default DashboardsTabContent;
