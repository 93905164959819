import styled from '@emotion/styled';

import Box, { StyledBoxProps } from '@components/Box';
import rootTheme from '@styles/theme';

interface StyledJobEdgeLabelButtonProps extends StyledBoxProps {
  isOpen?: boolean;
}

export const StyledJobEdgeLabelButton = styled(Box)<StyledJobEdgeLabelButtonProps>`
  background-color: ${({ isOpen }) => (isOpen ? '#E8F2FF' : 'white')};
`;

StyledJobEdgeLabelButton.defaultProps = {
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: '50%',
  borderStyle: 'solid',
  compDisplay: 'flex',
  compHeight: '20px',
  compWidth: '20px',
  justifyContent: 'center',
  pointerEvents: 'all',
  position: 'absolute',
  role: 'button',
};

export const StyledJobEdgeLabelCard = styled(Box)``;

StyledJobEdgeLabelCard.defaultProps = {
  backgroundColor: 'white',
  borderRadius: 'md',
  boxShadow: 'md',
  color: 'gray.500',
  compDisplay: 'flex',
  compWidth: '266px',
  flexDirection: 'column',
  fontSize: 'sm',
  fontWeight: 'regular',
  gap: 0.25,
  p: 1,
  role: 'dialog',
  zIndex: rootTheme.zIndex.floatingElement,
};

export const StyledJobEdgeLabelCardLabel = styled(Box)``;

StyledJobEdgeLabelCardLabel.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
  gap: 0.5,
};

export const StyledJobEdgeLabelCardFooter = styled(Box)``;

StyledJobEdgeLabelCardFooter.defaultProps = {
  alignItems: 'center',
  compDisplay: 'flex',
  justifyContent: 'flex-end',
};
