import { SIZES } from '@components/LineageExplore/components/Nodes/config';
import { ExploreNode, InputNodesById } from '@components/LineageExplore/LineageExplore.types';
import { DataNodeTypes } from '@components/LineageExplore/useCreateNodesEdges/algorithm/types';
import { EXPLORE_NODE_PAGE_SIZE } from '@components/LineageExplore/useShowMoreNodes/useShowMore.constants';

import { splitColumnsByUsage } from '../../../algorithm/algorithm.utils';
import { GroupNodeTypeConfigItem, NODE_TYPE_CONFIG } from '../createStacks.constants';
import { CreateStacksOptions } from '../types';

interface ParserDefaultParams {
  inputNodesById: InputNodesById;
  nodeId: string;
  options?: CreateStacksOptions;
  parentId?: string;
  stackId: number;
}

interface ParserResponse {
  isInitiallyCollapsed?: boolean;
  linkedObjs?: string[];
  parsedNode: ExploreNode;
}

interface ColumnParserParams extends ParserDefaultParams {
  isBITableColumn?: boolean;
  parentId: string;
  parents: string[];
}

export const columnParser = ({
  inputNodesById,
  isBITableColumn = false,
  nodeId,
  options,
  parentId,
  parents,
  stackId,
}: ColumnParserParams): ParserResponse => {
  const { enableColumnEdges } = options ?? {};
  const { hideDownstreamButton, hideUpstreamButton, metadata, shouldSkipEdgesCalculation, usage } =
    inputNodesById[nodeId];
  const {
    dataTypes,
    downstream_objects_count: downstreamObjectsCount,
    guid,
    is_nested: isNested,
    name,
    popularity,
    search_name: searchName,
    upstream_objects_count: upstreamObjectsCount,
  } = metadata;

  const parsedNode: ExploreNode = {
    data: {
      dataTypes,
      downstreamObjectsCount: downstreamObjectsCount ?? 0,
      guid,
      hiddenChildrenCount: 0,
      hideDownstreamButton,
      hideUpstreamButton,
      isBITableColumn,
      isNested,
      isOpen: false,
      isSelected: enableColumnEdges && !shouldSkipEdgesCalculation,
      key: nodeId,
      label: name,
      name,
      noMatchedChildren: false,
      parent: parentId,
      parents,
      popularity: popularity ?? 0,
      searchName,
      shownChildrenCount: EXPLORE_NODE_PAGE_SIZE,
      stackedAt: stackId,
      upstreamObjectsCount: upstreamObjectsCount ?? 0,
      usage,
    },
    height: SIZES.height.column,
    id: nodeId,
    key: nodeId,
    parent: parentId,
    parentNode: parentId,
    position: { x: 0, y: 0 },
    stackedAt: stackId,
    type: DataNodeTypes.column,
    width: isBITableColumn ? SIZES.width.biColumn : SIZES.width.column,
  };

  return { parsedNode };
};

interface TableParserParams extends ParserDefaultParams {
  isBiTable?: boolean;
  parentId: string;
  parents: string[];
  startingColumnGuid: undefined | string;
  startingTableId: string;
}

export const tableParser = ({
  inputNodesById,
  isBiTable,
  nodeId,
  options,
  parentId,
  parents,
  stackId,
  startingColumnGuid,
  startingTableId,
}: TableParserParams): ParserResponse => {
  const { openAll, shouldHideFilterLineage } = options ?? {};
  const table = inputNodesById[nodeId];
  const { columns, hideDownstreamButton, hideUpstreamButton, metadata } = table;
  const {
    dataTypes,
    downstream_objects_count: downstreamObjectsCount,
    guid,
    linked_objs: linkedObjs,
    name,
    popularity,
    search_name: searchName,
    upstream_objects_count: upstreamObjectsCount,
  } = metadata;
  const { findConfig } = dataTypes ?? {};
  const nodeConfig = findConfig?.<GroupNodeTypeConfigItem>(NODE_TYPE_CONFIG);

  let shownChildrenCount = EXPLORE_NODE_PAGE_SIZE;
  let hasRelevantColumnLineage = false;
  let isInitiallyCollapsed = false;

  if (shouldHideFilterLineage) {
    const { shown } = splitColumnsByUsage({
      columnsKeys: columns ?? [],
      nodesById: inputNodesById,
    });

    if (shown.length > 0) {
      hasRelevantColumnLineage = true;
      shownChildrenCount = Math.min(shown.length, EXPLORE_NODE_PAGE_SIZE);
    }

    if (shown.length <= 0 && nodeId !== startingTableId) {
      isInitiallyCollapsed = true;
    }
  }

  if (nodeId === startingTableId && startingColumnGuid) {
    const startingColumnPosition = (columns ?? []).indexOf(startingColumnGuid);
    if (startingColumnPosition > 0) {
      shownChildrenCount = Math.max(EXPLORE_NODE_PAGE_SIZE, startingColumnPosition + 1);
    }
  }

  const parsedNode: ExploreNode = {
    data: {
      children: new Set(columns ?? []),
      dataTypes,
      downstreamObjectsCount: downstreamObjectsCount ?? 0,
      guid,
      hasRelevantColumnLineage,
      hiddenChildrenCount: 0,
      hideColumns: nodeConfig?.hideColumns,
      hideDownstreamButton,
      hideUpstreamButton,
      isBITable: isBiTable,
      isOpen: openAll,
      key: nodeId,
      label: name,
      linkedObjs,
      name,
      noMatchedChildren: false,
      parent: parentId,
      parents,
      popularity: popularity ?? 0,
      searchName,
      shownChildrenCount,
      stackedAt: stackId,
      upstreamObjectsCount: upstreamObjectsCount ?? 0,
    },
    height: SIZES.height.table,
    id: nodeId,
    key: nodeId,
    parent: parentId,
    parentNode: parentId,
    position: { x: 0, y: 0 },
    stackedAt: stackId,
    type: DataNodeTypes.table,
    width: isBiTable ? SIZES.width.schema : SIZES.width.table,
  };

  return { isInitiallyCollapsed, linkedObjs, parsedNode };
};

interface SchemaParserParams extends ParserDefaultParams {
  children: Set<string>;
  parentId: string;
  schemaGuid: string;
}

export const schemaParser = ({
  children,
  inputNodesById,
  nodeId,
  parentId,
  schemaGuid,
  stackId,
}: SchemaParserParams): ParserResponse => {
  const schema = inputNodesById[schemaGuid];
  const { metadata } = schema;
  const { dataTypes, name, popularity, search_name: searchName } = metadata;

  const parsedNode: ExploreNode = {
    data: {
      children,
      childrenCount: 1,
      dataTypes,
      hiddenChildrenCount: 0,
      isOpen: true,
      key: nodeId,
      label: name,
      name,
      noMatchedChildren: false,
      parent: parentId,
      parents: [parentId],
      popularity: popularity ?? 0,
      searchName,
      shownChildrenCount: EXPLORE_NODE_PAGE_SIZE,
      stackedAt: stackId,
    },
    height: SIZES.height.schema,
    id: nodeId,
    key: nodeId,
    parent: parentId,
    parentNode: parentId,
    position: { x: 0, y: 0 },
    stackedAt: stackId,
    type: DataNodeTypes.schema,
    width: SIZES.width.schema,
  };

  return { parsedNode };
};

interface BiParserParams extends ParserDefaultParams {
  children: Set<string>;
  metadataNodeGuid: string;
}

export const biParser = ({
  children,
  inputNodesById,
  metadataNodeGuid,
  nodeId,
  stackId,
}: BiParserParams): ParserResponse => {
  const inputNode = inputNodesById[metadataNodeGuid];
  const metadata = inputNode?.metadata;
  const { dataTypes, popularity, search_name: searchName } = metadata;
  const { objectType } = dataTypes ?? {};

  const parsedNode: ExploreNode = {
    data: {
      children,
      childrenCount: 1,
      dataTypes,
      hiddenChildrenCount: 0,
      isOpen: true,
      key: nodeId,
      label: objectType,
      name: objectType,
      noMatchedChildren: false,
      popularity: popularity ?? 0,
      searchName,
      shownChildrenCount: EXPLORE_NODE_PAGE_SIZE,
      stackedAt: stackId,
      tablesCount: 1,
    },
    endStackId: stackId,
    id: nodeId,
    key: nodeId,
    nodeStacksConfig: {
      [stackId]: {
        hiddenChildren: new Set(),
        hiddenChildrenCount: 0,
        shownChildrenCount: EXPLORE_NODE_PAGE_SIZE,
      },
    },
    position: { x: 0, y: 0 },
    stackedAt: stackId,
    startStackId: stackId,
    type: DataNodeTypes.bi,
    width: SIZES.width.bi,
  };

  return { parsedNode };
};

interface DatabaseParserParams extends ParserDefaultParams {
  children: Set<string>;
  databaseGuid: string;
}

export const databaseParser = ({
  children,
  databaseGuid,
  inputNodesById,
  nodeId,
  stackId,
}: DatabaseParserParams): ParserResponse => {
  const database = inputNodesById[databaseGuid];
  const {
    metadata: { dataTypes, name, popularity, search_name: searchName },
  } = database;

  const parsedNode: ExploreNode = {
    data: {
      children,
      childrenCount: 1,
      dataTypes,
      hiddenChildrenCount: 0,
      isOpen: true,
      key: nodeId,
      label: name,
      name,
      noMatchedChildren: false,
      popularity: popularity ?? 0,
      searchName,
      shownChildrenCount: EXPLORE_NODE_PAGE_SIZE,
      stackedAt: stackId,
      tablesCount: 1,
    },
    endStackId: stackId,
    height: SIZES.height.database,
    id: nodeId,
    key: nodeId,
    position: { x: 0, y: 0 },
    stackedAt: stackId,
    startStackId: stackId,
    type: DataNodeTypes.database,
    width: SIZES.width.database,
  };

  return { parsedNode };
};
